import React from "react";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import PersonIcon from "@mui/icons-material/Person";
import { NavLink } from "react-router-dom";

function ApplicantCard({ applicant, index, jobId }) {
  return (
    <div
      key={index}
      class="w-full lg:w-1/4 p-2 cursor-pointer"
      style={{ fontFamily: "Lato" }}
    >
      <div class="relative p-2 bg-white shadow rounded-xl">
        <img
          class="w-32 h-32 p-1 mb-2 mx-auto rounded-full border border-indigo-50"
          src={
            applicant?.profile_completion?.profile_pic
            ? applicant?.profile_completion?.profile_pic
            : "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"
        }
        alt=""
        />
        <h3 class="text-center font-semibold text-md text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
          {applicant?.name}
        </h3>

        <p class="mb-3 text-center text-md text-gray-500 px-4 whitespace-nowrap overflow-hidden text-ellipsis">
          {applicant?.email}
        </p>

        <div class="text-center">
          <a class="relative group inline-block py-2 px-7 border font-semibold text-blue-900 hover:text-white rounded-full bg-white hover:bg-[#0F3D79] transition duration-300 overflow-hidden cursor-pointer">
            <a href={`/applicants/${jobId}/${applicant?._id}`} target="_blank">
              {" "}
              <span class="relative px-3 text-lg ">View Profile</span>
            </a>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ApplicantCard;
