import React, { useState, useEffect, useCallback } from "react";
import { fetchIOIProfile, ioiuserSelector, updateIOI } from "../../api/ioiUser";
import { useSelector, useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/joy/Button";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Autocomplete,
  Box,
  Grid,
  Checkbox,
  TextField,
  Tooltip,
  Select,
  InputLabel,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import InfoIcon from "@mui/icons-material/Info";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Textarea from "@mui/joy/Textarea";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import storage from "../../shared/firebaseStorage";
import { styled } from "@mui/joy";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Keyareas } from "../../shared/constants";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Image from "../../images/DomesticSolutions-16.jpg";
import Image1 from "../../images/AIforAI-17.png";
import Image2 from "../../images/DreamTrue-18.jpg";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useTheme } from "@mui/material/styles";
import { nanoid } from "@reduxjs/toolkit";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import { Interweave } from "interweave";
import Image3 from "../../images/broadcast.jpeg";
import Image4 from "../../images/Profile.png";
import useRazorpay from "react-razorpay";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const infoIcon = <InfoIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function ProfileSection() {
  const theme = useTheme();

  const { token, user } = useSelector(ioiuserSelector);

  const [Razorpay] = useRazorpay();

  const [formDataIOI, setFormDataIOI] = useState({
    authorized_person_name: "",
    designation: "",
    email: "",
    city: "",
    country: "",
    website_link: "",
  });

  var initialFormData = {
    logo: user?.profile_completion?.logo || "",
    authorized_person_name: user?.authorized_person_name || "",
    designation: user?.designation || "",
    authorized_person_profile_pic: user?.authorized_person_profile_pic || "",
    email: user?.email || "",
    city: user?.situated_at?.city || "",
    state: user?.situated_at?.state || "",
    country: user?.situated_at?.country || "",
    marketing_images: user?.profile_completion?.marketing_images || [],
    key_areas: user?.profile_completion?.key_areas || [],
    abt_company: user?.profile_completion?.abt_company || "",
    classified_as: user?.profile_completion?.classified_as || "",
    website_link: user?.profile_completion?.website_link || "",
  };

  // //console.log(
  //   initialFormData?.authorized_person_name,
  //   "jreiuiueruiyeruyieruyerytu"
  // );
  ////console.log(initialFormData?.city, "cityyyyyyyy");
  ////console.log(initialFormData?.state, "state");
  ////console.log(initialFormData?.country, "country");
  ////console.log(initialFormData?.website_link, "websitr limk");
  ////console.log(initialFormData?.authorized_person_profile_pic, "profilr pic");
  ////console.log(user, "classfied as");

  const useStyles = makeStyles((theme) => ({
    boxContainer: {
      marginBottom: "20px",
      // height: '100px',
      width: "100%",
      overflowWrap: "break-word", // Use overflowWrap to enable word wrapping
      wordWrap: "break-word", // For compatibility with older browsers
    },
  }));
  const classes = useStyles();

  const Text = `
The image must have a 1:1 ratio, be less than 100 KB in size, and can only be in .jpg, .png, or .webp format.
`;

  const longText = `
To ensure optimal display, images must be under 500 KB in size and saved in .jpg, .png, or .webp formats.
 For the best viewing experience, the image resolution should be 744 x 400 pixels or maintain a 1:1 aspect
 ratio. 

`;

  const Infotext = `
  As per the Government of India Standards  
`;

  const information = `
  Add image of Founder, CEO, Admin., etc`;

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = useState("");
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [italic, setItalic] = React.useState(false);
  const [fontWeight, setFontWeight] = React.useState("normal");
  const [formData, setFormData] = useState(initialFormData);

  const [logoPercent, setLogoPercent] = useState(0);
  const [profilePercent, setProfilePercent] = useState(0);
  const [uploadSuccessMsg, setLogoUploadSuccess] = useState("");
  const [uploadProfileSuccessMsg, setProfileUploadSuccess] = useState("");

  const [hideUpload, setHideUpload] = useState(false);
  const [uploadMarketSuccessMsg, setMarketUploadSuccess] = useState("");

  const [selectedFiles, setSelectedFiles] = useState(
    initialFormData.marketing_images
  );
  const [classif, setClassif] = useState(
    initialFormData.classified_as
  );

  const [authname, setAuthName] = useState(
    initialFormData.authorized_person_name
  );
  const [authnamePic, setAuthNamePic] = useState(
    initialFormData.authorized_person_profile_pic
  );
  const [desig, setDesig] = useState(initialFormData.designation);
  const [ioimail, setIoiMail] = useState(initialFormData.email);

  const [ioiCity, setIoiCity] = useState(initialFormData.city);
  const [ioiState, setIoiState] = useState(initialFormData.state);
  const [ioiCountry, setIoiCountry] = useState(initialFormData.country);
  const [linkWeb, setLinkWeb] = useState(initialFormData.website_link);

  const [imagePercent, setImagePercent] = useState(0);
  const [Logo, SetLogo] = useState(initialFormData.logo);
  const [text, setText] = useState(initialFormData.abt_company);
  const [classified, setClassified] = useState(initialFormData.classified_as);

  const [infotext, setInfotext] = React.useState("");
  const [website, setLink] = useState(initialFormData.website_link);

  const keyAreasAsObjects = initialFormData?.key_areas?.map((area) => ({
    title: area,
  }));
  const [selectedkeyArea, setSelectedkeyArea] = useState(keyAreasAsObjects);

  //
  const [paymentAmount, setPaymentAmount] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const dispatch = useDispatch();
  // const [ioiclassification, setIoiClassification] = useState("");

  const classification = [
    { label: "Micro", value: "Micro" },
    { label: "Small", value: "Small" },
    { label: "Medium", value: "Medium" },
    { label: "Large", value: "Large" },
    { label: "Not Applicable", value: "Not Applicable" },
  ];
  
  const images = [
    user?.profile_completion?.marketing_images[0],
    user?.profile_completion?.marketing_images[1],
    user?.profile_completion?.marketing_images[2],
    user?.profile_completion?.marketing_images[3],
  ];

  // const images= user?.profile_completion?.marketing_images?.map((i)=> i).slice(0,4);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  // useEffect(() => {
  //   const interval = setInterval(nextImage, 3000);
  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    dispatch(fetchIOIProfile(token));
  }, [token]);

  useEffect(() => {
    preFill();
  }, [user]);

  useEffect(() => {
    amountCalculation();
  }, [user]);

  const preFill = async () => {
    setLogoUploadSuccess("");
    setMarketUploadSuccess("");
    setProfileUploadSuccess("");
    // setPaymentAmount(user?.sub_category
    //   || 100);
    setFormData({
      logo: user?.profile_completion?.logo
        ? user?.profile_completion?.logo
        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" ||
          "",
      authorized_person_profile_pic: user?.authorized_person_profile_pic
        ? user?.authorized_person_profile_pic
        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" ||
          "",
      authorized_person_name: user?.authorized_person_name || "",
      designation: user?.designation || "",
      email: user?.email || "",
      city: user?.situated_at?.city || "",
      state: user?.situated_at?.state || "",
      country: user?.situated_at?.country || "",
      marketing_images: user?.profile_completion?.marketing_images || [],
      key_areas: user?.profile_completion?.key_areas || [],
      abt_company: user?.profile_completion?.abt_company || "",
      website_link: user?.profile_completion?.website_link || "",
      classified_as: user?.profile_completion?.classified_as || "",
    });

    initialFormData = {
      authorized_person_name: user?.authorized_person_name || "",
      authorized_person_profile_pic: user?.authorized_person_profile_pic || "",
      designation: user?.designation || "",
      email: user?.email || "",
      city: user?.situated_at?.city || "",
      state: user?.situated_at?.state || "",
      country: user?.situated_at?.country || "",
      logo: user?.profile_completion?.logo || "",
      marketing_images: user?.profile_completion?.marketing_images || [],
      key_areas: user?.profile_completion?.key_areas || [],
      abt_company: user?.profile_completion?.abt_company || "",
      website_link: user?.profile_completion?.website_link || "",
      classified_as: user?.profile_completion?.classified_as || "",
    };

    SetLogo(initialFormData.logo);
    setAuthName(initialFormData.authorized_person_name);
    setAuthNamePic(initialFormData.authorized_person_profile_pic);
    setSelectedFiles(initialFormData.marketing_images);
    ////console.log(authnamePic, "2=nd pic");

    setDesig(initialFormData.designation);
    setIoiMail(initialFormData.email);
    setIoiCity(initialFormData.city);
    setIoiState(initialFormData.state);
    setIoiCountry(initialFormData.country);
    setLinkWeb(initialFormData.website_link);
    setClassified(initialFormData.classified_as);

    setLogoPercent(100);
    setProfilePercent(100);
    setText(initialFormData.abt_company);

    // setClassified(initialFormData?.classified_as);
    const keyAreasAsObjects = initialFormData?.key_areas?.map((area) => ({
      title: area,
    }));
    setSelectedkeyArea(keyAreasAsObjects);
    setLink(user?.profile_completion?.website_link);
  };

  const amountCalculation = async () => {
    if (user?.category == "Institution") {
      setPaymentAmount(1000);
    }
    if (user?.category == "Organisation") {
      if (
        user?.sub_category == "Central Government Department" ||
        user?.sub_category == "State Government Department" ||
        user?.sub_category == "Union Territory Government Department"
      ) {
        setPaymentAmount(2000);
      } else if (
        user?.sub_category == "National Level Research Organisation" ||
        user?.sub_category == "State Level Research Organisation" ||
        user?.sub_category ==
          "Scientific and Industrial Research Organisation Registered with DSIR"
      ) {
        setPaymentAmount(1000);
      } else {
        setPaymentAmount(1000);
      }
    }
    if (user?.category == "Industry") {
      if (
        user?.sub_category == "BSE Listed Company" ||
        user?.sub_category == "NSE Listed Company"
      ) {
        setPaymentAmount(2000);
      } else if (user?.sub_category == "Startup ") {
        setPaymentAmount(500);
      } else if (user?.sub_category == "Unicorn Company") {
        setPaymentAmount(1500);
      } else {
        setPaymentAmount(1000);
      }
    }
    if (user?.category == "Investment") {
      if (user.sub_category == "Investment company") {
        setPaymentAmount(2000);
      } else if (user?.sub_category == "Government Startup Program") {
        setPaymentAmount(1000);
      } else {
        setPaymentAmount(1000);
      }
    }
  };

  useEffect(() => {
    const keyInterst = selectedkeyArea.map((item) => {
      return item.title;
    });
    setKeyAres(keyInterst);
  }, [selectedkeyArea]);

  const handleTextChange = (event) => {
    const newText = event.target.value;
    if (newText.length <= 1000) {
      setText(newText);
    }
    // else
    // setCharacterLimit('please stop typing')
  };

  const handleAuthName = (event) => {
    const newText = event.target.value;
    setAuthName(newText);
    // else
    // setCharacterLimit('please stop typing')
  };
  const handleClass = (event) => {
    const newText = event.target.value;
    setClassified(newText);
    // else
    // setCharacterLimit('please stop typing')
  };

  const handleDesign = (event) => {
    const newText = event.target.value;
    setDesig(newText);
    // else
    // setCharacterLimit('please stop typing')
  };

  const handleMail = (event) => {
    const newText = event.target.value;
    setIoiMail(newText);
    // else
    // setCharacterLimit('please stop typing')
  };

  const handleCity = (event) => {
    const newText = event.target.value;
    setIoiCity(newText);

    // else
    // setCharacterLimit('please stop typing')
  };
  const handleState = (event) => {
    const newText = event.target.value;
    setIoiState(newText);

    // else
    // setCharacterLimit('please stop typing')
  };

  const handleCountry = (event) => {
    const newText = event.target.value;
    setIoiCountry(newText);

    // else
    // setCharacterLimit('please stop typing')
  };

  const handleLinkWeb = (event) => {
    const newText = event.target.value;
    setLinkWeb(newText);

    // else
    // setCharacterLimit('please stop typing')
  };

  const [characterLimit, setCharacterLimit] = useState(
    "Your description cannot exceed 1000 characters and above."
  );

  ////console.log(user?.profile);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 500,
    bgcolor: "background.paper",
    border: "px solid #000",
    boxShadow: 2,
    p: 2,
  };

  const VisuallyHiddenInput = styled("input")`
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: visible;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap
    width: 1px;
  `;

  const handleFileInputChange = (event) => {
    setLogoPercent(0);
    const newSelectedFile = event.target.files[0];
    if (newSelectedFile) {
      const isJpgOrPng =
        newSelectedFile.type === "image/jpeg" ||
        newSelectedFile.type === "image/png" ||
        newSelectedFile.type === "image/webp";

      if (!isJpgOrPng) {
        toast.warning("You can only upload JPG/PNG/WEBP file!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeButton: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      const isLt100KB = newSelectedFile.size / 1024 < 100;
      if (!isLt100KB) {
        toast.warning("Image size must be less than 100kb", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeButton: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      if (isJpgOrPng && isLt100KB) {
        const storageRef = ref(
          storage,
          `/IOI Profile Completion/${nanoid() + newSelectedFile.name}`
        );
        ////console.log(storageRef);
        const uploadFile = uploadBytesResumable(storageRef, newSelectedFile);
        uploadFile.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // update progress
            setLogoPercent(percent);
            if (percent == 100)
              setLogoUploadSuccess("Logo Uploaded Successfully.");
          },
          (err) => {
            console.error(err);
          },
          () => {
            // download url
            getDownloadURL(uploadFile.snapshot.ref).then((url) => {
              SetLogo(url);
              ////console.log(Logo, "setlogo");
            });
          }
        );
      }
    }
  };

  const handleFileInputChange2 = (event) => {
    setProfilePercent(0);
    const newSelectedFile = event.target.files[0];
    if (newSelectedFile) {
      const isJpgOrPng =
        newSelectedFile.type === "image/jpeg" ||
        newSelectedFile.type === "image/png" ||
        newSelectedFile.type === "image/webp";
      if (!isJpgOrPng) {
        toast.warning("You can only upload JPG/PNG/WEBP file!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeButton: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return; // Exit the function if the file type is not valid
      }
      const isLt100KB = newSelectedFile.size / 1024 < 100;
      if (!isLt100KB) {
        toast.warning("Image size must be less than 100kb", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeButton: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return; // Exit the function if the file size is not valid
      }
      if (isJpgOrPng && isLt100KB) {
        const storageRef = ref(
          storage,
          `/IOI Profile Completion/${nanoid() + newSelectedFile.name}`
        );

        const uploadFile = uploadBytesResumable(storageRef, newSelectedFile);

        uploadFile.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // update progress
            setProfilePercent(percent);
            if (percent === 100) {
              setProfileUploadSuccess("Profile Uploaded Successfully.");
            }
          },
          (err) => {
            console.error(err);
          },
          () => {
            // download url
            getDownloadURL(uploadFile.snapshot.ref).then((url) => {
              setAuthNamePic(url);
            });
          }
        );
      }
    }
  };

  const handleDeleteClickLogo = () => {
    ////console.log("Clicked Delete", Logo);
    if (Logo) {
      const firebaseStorageURL = Logo;
      const urlParts = firebaseStorageURL.split("?");
      const pathParts = urlParts[0].split("/");
      const fileName = decodeURIComponent(pathParts[pathParts.length - 1]);
      const desertRef = ref(storage, `${fileName}`);
      deleteObject(desertRef)
        .then(() => {
          // File deleted successfully
          SetLogo(null);
          setLogoUploadSuccess("");
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.log(error, "error");
        });
    }
    SetLogo(null);
    setLogoUploadSuccess("");
  };

  const handleDeleteClickLogo2 = () => {
    ////console.log("Clicked Delete", authnamePic);
    if (authnamePic) {
      const firebaseStorageURL = authnamePic;
      const urlParts = firebaseStorageURL.split("?");
      const pathParts = urlParts[0].split("/");
      const fileName = decodeURIComponent(pathParts[pathParts.length - 1]);
      const desertRef = ref(storage, `${fileName}`);
      deleteObject(desertRef)
        .then(() => {
          // File deleted successfully
          setAuthNamePic(null);
          setProfileUploadSuccess("");
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.log(error);
        });
    }
    setAuthNamePic(null);
    setProfileUploadSuccess("");
  };

  const handleFileInputChange1 = (event) => {
    setHideUpload(false);
    ////console.log("edit");
    const files = event.target.files;
    // Iterate through the selected files and upload them
    Array.from(files).forEach((file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/webp";
      if (!isJpgOrPng) {
        toast.warning("You can only upload JPG/PNG file!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeButton: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      const isLt300KB = file.size / 1024 < 300;
      if (!isLt300KB) {
        toast.warning("Image size must be less than 300kb", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeButton: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      if (isJpgOrPng && isLt300KB) {
        const storageRef = ref(
          storage,
          `/IOI Profile Completion Marketing Images/${nanoid() + file.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // Update progressAbout
            setImagePercent(percent);
            setHideUpload(true);
            if (percent == 100) {
              // setHideUpload(false)
              setMarketUploadSuccess("Uploaded Successfully.");
            }
          },
          (err) => {
            console.error(err);
          },
          () => {
            // Upload is complete, get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setSelectedFiles([...selectedFiles, downloadURL]);
            });
          }
        );
      }
    });
  };

  const handleDeleteClick = (index) => {
    if (selectedFiles.length != 0) {
      const newSelectedFiles = [...selectedFiles];
      const document = newSelectedFiles.splice(index, 1); // Remove the selected file at the given index
      const firebaseStorageURL = document[0];
      const urlParts = firebaseStorageURL.split("?");
      const pathParts = urlParts[0].split("/");
      const fileName = decodeURIComponent(pathParts[pathParts.length - 1]);
      if (fileName.includes("Default Banner Images")) {
      // if(fileName) {
        ////console.log("Inside if");
        setSelectedFiles(newSelectedFiles);
        setImagePercent(0);
        setHideUpload(false);
        setMarketUploadSuccess("");
      } else {
        ////console.log("inside else");
        const desertRef = ref(storage, `${fileName}`);
        deleteObject(desertRef)
          .then(() => {
            // File deleted successfully
            setSelectedFiles(newSelectedFiles);
            setImagePercent(0);
            setHideUpload(false);
            setMarketUploadSuccess("");
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
            console.log(error);
          });
      }
    } else {
      const newSelectedFiles = [...selectedFiles];
      newSelectedFiles.splice(index, 1); // Remove the selected file at the given index
      setSelectedFiles(newSelectedFiles);
      setImagePercent(0);
      setHideUpload(false);
      setMarketUploadSuccess("");
    }
  };

  const clearAll = () => {
    if (selectedFiles.length !== 0) {
      const promises = [];

      //iterate over the array
      selectedFiles.forEach((firebaseStorageURL) => {
        const urlParts = firebaseStorageURL.split("?");
        const pathParts = urlParts[0].split("/");
        const fileName = decodeURIComponent(pathParts[pathParts.length - 1]);

        // Check if the file belongs to the "Default Banner Images" folder
        if (!fileName.includes("Default Banner Images")) {
          // Perform deletion for files not in the "Default Banner Images" folder
          const desertRef = ref(storage, `${fileName}`);
          const deletePromise = deleteObject(desertRef);
          promises.push(deletePromise);
        }
      });

      Promise.all(promises)
        .then(() => {
          // All relevant files deleted successfully
          setSelectedFiles([]);
          setImagePercent(0);
          setHideUpload(false);
          setMarketUploadSuccess("");
        })
        .catch((error) => {
          // Handle any errors that occurred during deletion
          console.log(error);
        });
    } else {
      setSelectedFiles([]);
      setImagePercent(0);
      setHideUpload(false);
      setMarketUploadSuccess("");
    }
  };

  const combinedArray = Array.from(
    new Set(
      [...Keyareas, ...keyAreasAsObjects].map((item) => ({ title: item.title }))
    )
  );

  const [keyAreas, setKeyAres] = useState([]);
  const navigate = useNavigate();

  const changeHandel = (e, value) => {
    setSelectedkeyArea([...value]);
  };

  const handleKeyAreaSelection = (value) => {
    if (value.length <= 6) {
      setSelectedkeyArea([...value]);
    }
    if (value.length > 6)
      toast.warning("You exceeded limit of 6", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  };

  const columnStyles = {
    columnCount: 3,
    columnGap: "16px", // Adjust the gap as needed
    overflowX: "auto", // Enable vertical scrolling
  };

  const onFinish = () => {
    // Ensure all values are set correctly
    const data = {
      authorized_person_name: authname,
      designation: desig,
      email: ioimail,
      authorized_person_profile_pic: authnamePic,
      situated_at: {
        city: ioiCity,
        state: ioiState,
        country: ioiCountry,
      },
      profile_completion: {
        logo: Logo,
        marketing_images: selectedFiles,
        key_areas: keyAreas,
        abt_company: text,
        status: true,
        website_link: linkWeb,
        classified_as: classified,
      },
    };

    // Log the entire data object to check values before dispatch
    ////console.log("Data to be dispatched:", data);

    // Dispatch the update action
    dispatch(updateIOI(user?._id, data, token));

    // Log the profile picture value to check if it's set correctly
    ////console.log("Authorized person profile pic:", authnamePic);

    // Close all open states
    setOpen(false);
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);

    // Navigate to the home page
    navigate("/home");
  };

  // const handlePayment = () => {
  //   alert(paymentAmount)
  // }

  const handlePayment = () => {
    // alert(paymentAmount)
    var finalAmt = Number(paymentAmount) * 100;
    const options = {
      key: "rzp_live_DdtLCdVoEvVBt1",
      amount: finalAmt,
      currency: "INR",
      name: "BHARC",
      description: "Test Transaction",
      image: "../images/logo.png",
      // order_id: order.id,
      handler: (res) => {
        ////console.log(res);
      },
      prefill: {
        name: "BHARC",
        email: "info@bharc.in",
        contact: "1234567899",
      },
      theme: {
        color: "#e0f3fa",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  return (
    <div>
      {/* <div style={{ backgroundColor: '#E0F3FA', color: '#fff', padding: '20px' }}>
        <h3 style={{ marginBottom: '10px', fontSize:'35px', color:'#000'}}>Hey, your free trail period is over</h3>
        <p style={{ marginBottom: '25px', fontSize:'15px', color:'#000'}}>We hope you enjoyed your free trail. If you want to continue with BHARC service & unlock more features, please proceed with the payment.</p>
        <div onClick={handlePayment}
          className="py-2 px-7 border font-semibold rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer w-[125px] text-[#0F3D79] hover:bg-[#0F3D79] hover:text-white"
        >
              <span> Pay Now </span>
        </div>
      </div> */}

      <section class="pt-10" style={{ fontFamily: "Lato" }}>
        <div class="container mx-auto">
          <div class="flex flex-wrap">
            <div class="w-full lg:w-[25%] px-3 mt-4">
              <div class="mb-6">
                <div class="relative bg-gray-100 shadow rounded-lg overflow-hidden h-[400px]">
                  <div style={{ marginLeft: "90%" }}>
                    <button class="block w-full text-md font-semibold text-gray-300 hover:text-gray-500 mt-3">
                      <EditIcon onClick={() => setOpen1(true)} />
                    </button>
                  </div>
                  <img
                    class="block mx-auto mb-5 w-32 h-32 rounded-full"
                    src={
                      user?.profile_completion?.logo
                        ? user?.profile_completion?.logo
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                    alt=""
                  />
                  <div class="px-6 text-center">
                    <div class="flex items-center justify-center mb-1">
                      {/* <span class="mr-2">
                      <h4 class="text-md font-semibold text-gray-800 mb-1">Title :</h4>
                      </span> */}
                      <p
                        class="text-lg font-semibold"
                        style={{ color: "#0F3D79" }}
                      >
                        {user?.name}
                      </p>
                    </div>
                    <div class="flex items-center justify-center mb-16">
                      {/* <span class="mr-2">
                      <h4 class="text-md font-semibold text-gray-800 mb-1">Sub Category</h4>
                      </span> */}
                      <p class="text-md font-semibold text-gray-500">
                        {user?.sub_category}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-5 bg-gray-100 rounded-xl overflow-hidden mt-4 mb-2 hidden sm:block">
                <h3 class="text-lg font-semibold " style={{ color: "#0F3D79" }}>
                  Projects & Entrepreneurship
                </h3>
                <Link
                  class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                  to="/bharc"
                >
                  <img className="h-12 w-[137px] object-cover" src={Image3} />
                </Link>
                <Link
                  class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                  to="/domesticsolutions"
                >
                  <img className="h-12" src={Image} />
                </Link>
                <Link
                  class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                  to="/AI"
                >
                  <img className="h-12" src={Image1} />
                </Link>
                <Link
                  class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                  to="/dreamtrue"
                >
                  <img className="h-12" src={Image2} />
                </Link>
              </div>
            </div>
            <div class="w-full lg:w-[50%]   px-3 mt-4">
              <div class="container mx-auto">
                <div class="relative bg-gray-100 shadow rounded-lg overflow-hidden h-[270px] md:h-[400px]">
                  <div className=" border-b border-gray-200">
                    <div className="relative text-center">
                      <img
                        className="block mx-auto rounded-lg h-[270px] md:h-[400px]"
                        src={images[currentImageIndex]}
                        alt=""
                      />
                      <div className="absolute top-1/2 left-0 transform -translate-y-1/2">
                        <button className="text-[#0F3D79]" onClick={prevImage}>
                          <NavigateBeforeIcon />
                        </button>
                      </div>
                      <div className="absolute top-1/2 right-0 transform -translate-y-1/2">
                        <button className="text-[#0F3D79]" onClick={nextImage}>
                          <NavigateNextIcon />
                        </button>
                      </div>

                      <div
                        className="absolute  right-0 transform -translate-y-1/2"
                        style={{ bottom: "85%" }}
                      >
                        {/* <div className="absolute" style={{ marginLeft: "90%" }}> */}
                        <button class="block w-full text-md font-semibold bg-slate-100 rounded-md px-2 text-gray-300 hover:text-gray-500 mt-3">
                          <EditIcon onClick={() => setOpen2(true)} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full mb-6 lg:mb-0 mt-5 pt-1">
                  <div className="mb-6">
                    <div className="py-4 px-6 bg-gray-100 rounded-xl h-[360px] flex flex-col">
                      <div className="flex justify-between items-center mb-4">
                        <h3
                          className="text-lg font-semibold"
                          style={{ color: "#0F3D79" }}
                        >
                          About
                        </h3>
                        <button className="w-auto  text-gray-300 hover:text-gray-500">
                          <EditIcon onClick={() => setOpen(true)} />
                        </button>
                      </div>
                      <div
                        className="flex flex-wrap overflow-y-auto"
                        style={{
                          maxHeight: "calc(100% - 56px)",
                          overflowX: "hidden",
                        }}
                      >
                        <div className="leading-normal text-gray-500 font-medium">
                          <Interweave
                            content={user?.profile_completion?.abt_company}
                            customElementAttributes={{
                              ul: { className: "custom-ul" },
                              li: { className: "custom-li" },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full lg:w-[25%] px-3 mt-4">
              <div class="p-6 bg-gray-100 rounded-xl h-[400px]">
                <div class="flex flex-wrap -mx-3 mb-3 justify-between items-center">
                  <div class="w-auto px-3">
                    {/* <h4 class="text-lg text-indigo-800 font-semibold"> */}
                    <h4
                      class="text-lg  font-semibold"
                      style={{ color: "#30679E" }}
                    >
                      Interested Domains
                    </h4>
                  </div>
                  <button class="w-auto  text-gray-300 hover:text-gray-500">
                    <EditIcon onClick={() => setOpen3(true)} />
                  </button>
                </div>

                <div class="w-full">
                  <div class="h-full rounded-xl">
                    <div class="mb-8 -mx-4">
                      <div class="-mb-3 text-start white-space-no-wrap">
                        {user?.profile_completion?.key_areas.map((item, i) => {
                          return (
                            i < 6 && (
                              <p class="px-2 pl-5 py-1 my-1 mb-1  text-gray-500 bg-gray-200 text-sm font-medium leading-6 rounded-full">
                                {item}
                              </p>
                            )
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-6 bg-gray-100 rounded-xl mt-5 h-[360px]">
                <div class="flex flex-wrap -mx-3 mb-3 justify-between items-center">
                  <div class="w-auto px-3">
                    {/* <h4 class="text-lg text-indigo-900 font-semibold"> */}
                    <h4
                      class="text-lg font-semibold"
                      style={{ color: "#0F3D79" }}
                    >
                      General Information
                    </h4>
                  </div>
                  <button class="w-auto  text-gray-300 hover:text-gray-500 ">
                    <EditIcon onClick={() => setOpen5(true)} />
                  </button>
                </div>

                <div className="flex mt-2">
                  <h4 className="text-md text-gray-500 font-medium mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
                    Main Category :
                  </h4>
                  <p className="ml-2 text-md font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
                    {user?.category.charAt(0).toUpperCase() +
                      user?.category.slice(1)}
                  </p>
                </div>

                <div className="flex mt-2">
                  <h4 class="text-md text-gray-500 font-medium mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
                    Establishment Year :
                  </h4>
                  <p class="ml-2 text-md font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
                    {user?.establish_year}
                  </p>
                </div>
                <div className="flex mt-2">
                  <h4 class="text-md text-gray-500 font-medium mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
                    Classification :
                  </h4>
                  <p class="ml-2 text-md font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
                    {user?.profile_completion?.classified_as}
                  </p>
                </div>
                {/* <div className="flex items-center mt-1">
                  <Tooltip title={Infotext}>
                    <p className="ml-2">
                      <InfoOutlinedIcon />
                    </p>
                  </Tooltip>
                </div> */}
              </div>
            </div>

            {/* modal for classification */}

            {/*  <div class="flex flex-wrap w-full">
              <div class="w-1/2 p-3 ">
                <div class="p-4 bg-gray-100 rounded-xl px-[10%]">
                  <h4 class="text-lg text-indigo-900 font-semibold py-3">
                    Additional Information
                  </h4>
                  <div className=" flex mt-2">
                    <h4 class="text-md text-gray-500 font-medium mb-1">
                      Authorized person :
                    </h4>
                    <p class="ml-2 text-md font-semibold text-gray-800">
                      {user?.authorized_person_name}
                    </p>
                  </div>
                  <div className=" flex mt-2">
                    <h4 class="text-md  text-gray-500 font-medium mb-1">
                      Designation :
                    </h4>
                    <p class="ml-2 text-md font-semibold text-gray-800">
                      {user?.designation}
                    </p>
                  </div>
                  <div className=" flex mt-2">
                    <h4 class="text-md  text-gray-500 font-medium mb-1">
                      &nbsp;
                    </h4>
                    <p class="ml-2 text-md font-semibold text-gray-800">
                    &nbsp;
                    </p>
                  </div>
                </div>
              </div>


              <div class="w-1/2 p-3">
                <div class="p-4 bg-gray-100 rounded-xl px-[10%]">
                  <h4 class="text-lg text-indigo-900 font-semibold py-3">
                    Additional Information
                  </h4>
                  <div className="flex mt-2">
                    <h4 class="text-md text-gray-500 font-medium mb-1">
                      Headquarters :
                    </h4>
                    <p class="ml-2 text-md font-semibold text-gray-800">
                      {user?.situated_at?.city.charAt(0).toUpperCase() +
                        user?.situated_at?.city.slice(1)}{" "}
                      ,{" "}
                      {user?.situated_at?.state.charAt(0).toUpperCase() +
                        user?.situated_at?.state.slice(1)}{" "}
                    </p>
                  </div>

                  <div className="flex mt-2">
                    <h4 class="text-md text-gray-500 font-medium mb-1">
                      Country:
                    </h4>
                    <p class="ml-2 text-md font-semibold text-gray-800">
                      {user?.situated_at?.country}
                    </p>
                  </div>

                  <div className="flex mt-2">
                    <h4 class="text-md text-gray-500 font-medium mb-1">
                      Website:
                    </h4>
                    <a
                      href={user?.profile_completion?.website_link}
                      target="_blank"
                      class="ml-2 text-md font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis"
                    >
                      {user?.profile_completion?.website_link}
                    </a>
                  </div>
                </div>
              </div>

            </div> */}
            {/* <div class="container mx-auto"> */}
            <div class="flex-wrap bg-gray-100 rounded-xl pl-6 pr-2 w-full lg:w-[100%] ">
              <div class="flex flex-wrap  justify-between items-center">
                <div class="w-full flex justify-between">
                  {/* <h3 class="text-lg font-semibold text-indigo-900 py-3 mt-2 mb-3"> */}
                  <h3
                    class="text-lg font-semibold py-3 mt-2 mb-3"
                    style={{ color: "#0F3D79" }}
                  >
                    Additional Information
                    {/* <Tooltip title={information}>
                        <p className="ml-2">
                          <InfoOutlinedIcon />
                        </p>
                      </Tooltip> */}
                  </h3>

                  <button class="w-auto  text-gray-300 hover:text-gray-500 ">
                    <EditIcon onClick={() => setOpen4(true)} />
                  </button>
                </div>
              </div>
              <div class="flex flex-wrap">
                <div class="w-full p-3 lg:w-[33.33%]">
                  <div className="flex mt-2">
                    {/* <img class="w-50 h-40" src={Image4} /> */}
                    <img
                      class="block mx-auto mb-5 w-40 h-40  rounded-full"
                      src={
                        user?.authorized_person_profile_pic
                          ? user?.authorized_person_profile_pic
                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div class="w-full p-3 lg:w-[33.33%] mt-5">
                  <div className=" flex mt-2">
                    <h4 class="text-md text-gray-500 font-medium mb-1">
                      Authorized person :
                    </h4>
                    <p class="ml-2 mt-1 text-sm font-semibold text-gray-800">
                      {user?.authorized_person_name}
                    </p>
                  </div>
                  <div className=" flex mt-2">
                    <h4 class="text-md  text-gray-500 font-medium mb-1">
                      Designation :
                    </h4>
                    <p class="ml-2 mt-1 text-sm font-semibold text-gray-800">
                      {user?.designation}
                    </p>
                  </div>
                  <div className=" flex mt-2">
                    <h4 class="text-md  text-gray-500 font-medium mb-1">
                      E-mail :
                    </h4>
                    <p class="ml-2 mt-1 text-sm font-semibold text-gray-800">
                      {user?.email}
                    </p>
                  </div>
                  <div className=" flex mt-2">
                    <h4 class="text-md  text-gray-500 font-medium mb-1">
                      &nbsp;
                    </h4>
                    <p class="ml-2 text-sm font-semibold text-gray-800">
                      &nbsp;
                    </p>
                  </div>
                </div>
                <div class="w-full p-3 lg:w-[33.33%] mt-0 lg:mt-5 mb-4">
                  <div className="flex mt-2">
                    <h4 class="text-md text-gray-500 font-medium mb-1">
                      Headquarters :
                    </h4>
                    <p class="ml-2 mt-1 text-sm font-semibold text-gray-800">
                      {user?.situated_at?.city.charAt(0).toUpperCase() +
                        user?.situated_at?.city.slice(1)}{" "}
                      ,{" "}
                      {user?.situated_at?.state.charAt(0).toUpperCase() +
                        user?.situated_at?.state.slice(1)}{" "}
                    </p>
                  </div>

                  <div className="flex mt-2">
                    <h4 class="text-md text-gray-500 font-medium mb-1">
                      Country:
                    </h4>
                    <p class="ml-2 mt-1 text-sm font-semibold text-gray-800">
                      {user?.situated_at?.country}
                    </p>
                  </div>

                  <div className="flex mt-2">
                    <h4 class="text-md text-gray-500 font-medium mb-1">
                      Website:
                    </h4>
                    <a
                      href={user?.profile_completion?.website_link}
                      target="_blank"
                      class="ml-2 mt-1 text-sm font-semibold text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis"
                    >
                      {user?.profile_completion?.website_link}
                    </a>
                  </div>
                </div>
                {/*  <div class="w-full p-3 lg:w-[33.33%]">
                    <div className="flex mt-2 justify-center alignItems-center">
                      <img class="w-40 h-40" src={Image4} />
                    </div>
                  </div> */}
              </div>
            </div>
            {/* </div> */}

            {/* Logic of edit goes here...... */}
            <div>
              {/* ---------------------------------------logo image----------------------------------------------- */}

              <React.Fragment>
                <Modal open={open1}>
                  <ModalDialog
                    aria-labelledby="nested-modal-title"
                    aria-describedby="nested-modal-description"
                    sx={(theme) => ({
                      [theme.breakpoints.only("xs")]: {
                        top: "unset",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        borderRadius: 0,
                        transform: "none",
                        maxWidth: "unset",
                      },
                    })}
                  >
                    <div class="flex flex-wrap py-5">
                      <div class="w-full md:w-auto p-2 ">
                        <div className="w-full flex justify-center items-center">
                          {/* <h2 className="text-[#0F3D79] text-md w-full font-semibold"> */}
                          <span className="ml-8">
                            Upload Logo image:
                            {/* </h2> */}
                            <Tooltip title={Text}>
                              <InfoOutlinedIcon />
                            </Tooltip>
                          </span>
                        </div>

                        <div className="ml-8">
                          <label>
                            <Button
                              style={{ marginTop: "3%" }}
                              component="label"
                              role={undefined}
                              tabIndex={-1}
                              variant="outlined"
                              color="neutral"
                            >
                              {Logo ? (
                                <>
                                  <img
                                    src={Logo}
                                    className="block mx-auto mb-5 w-28 h-28 rounded-full "
                                  />
                                  <button
                                    type="button"
                                    className=""
                                    onClick={handleDeleteClickLogo}
                                  >
                                    <p className="text-gray-400 hover:text-gray-700">
                                      <DeleteIcon />
                                    </p>
                                  </button>
                                </>
                              ) : (
                                <input
                                  className="w-24"
                                  type="file"
                                  accept="image/*"
                                  onChange={handleFileInputChange}
                                />
                              )}
                            </Button>
                          </label>

                          <>
                            {logoPercent < 100 && Logo ? (
                              <span
                                className="ml-5 font-semibold"
                                style={{
                                  color: logoPercent < 100 ? "red" : "green",
                                }}
                              >
                                Upload {logoPercent}%
                              </span>
                            ) : (
                              <p
                                className="font-normal"
                                style={{ color: "green" }}
                              >
                                {uploadSuccessMsg}
                              </p>
                            )}
                          </>
                        </div>

                        <Box
                          sx={{
                            mt: 2,
                            display: "flex",
                            marginLeft: "30px",
                            gap: 1,
                            // flexDirection: { xs: "column", sm: "row-reverse" },
                          }}
                        >
                          <Button
                            variant="outlined"
                            color="neutral"
                            onClick={() => {
                              setOpen1(false);
                              preFill();
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="solid"
                            color="contained"
                            style={{
                              backgroundColor: "#0066A2",
                              color: "white",
                            }}
                            onClick={onFinish}
                          >
                            Submit
                          </Button>
                        </Box>
                      </div>
                    </div>
                  </ModalDialog>
                </Modal>
              </React.Fragment>

              {/*---------------------------------------Marketing images-------------------------- */}
              <React.Fragment>
                <Modal open={open2}>
                  <ModalDialog
                    aria-labelledby="nested-modal-title"
                    aria-describedby="nested-modal-description"
                    sx={(theme) => ({
                      [theme.breakpoints.only("xs")]: {
                        top: "unset",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        borderRadius: 0,
                        transform: "none",
                        maxWidth: "unset",
                        overflow: "auto", // Add this line to set overflow to auto for mobile view
                      },
                    })}
                  >
                    <div className="flex items-center justify-center p-2">
                      <div className="flex">
                        <h2 className="text-[#0F3D79] text-lg font-semibold">
                          Upload Images
                        </h2>
                        <Tooltip title={longText}>
                          <p className="ml-2">
                            <InfoOutlinedIcon />
                          </p>
                        </Tooltip>
                      </div>
                    </div>
                    <div>
                      <label>
                        {selectedFiles.length >= 4 ? (
                          <div className="flex justify-center">
                            <span
                              className="ml-5 font-semibold"
                              style={{ color: "green" }}
                            >
                              {selectedFiles.length} files
                            </span>
                            <span
                              className="ml-5 font-semibold"
                              style={{
                                color: imagePercent < 100 ? "red" : "green",
                              }}
                            >
                              Upload {imagePercent}%
                            </span>
                          </div>
                        ) : (
                          <>
                            <Button
                              style={{
                                padding: "10px",
                                marginLeft: "50px",
                                marginTop: "10px",
                              }}
                              component="label"
                              role={undefined}
                              tabIndex={-1}
                              variant="outlined"
                              color="neutral"
                            >
                              {/* <AddIcon /> */}
                              {/* Add Images */}
                              <input
                                className="w-24"
                                type="file"
                                accept="image/*"
                                multiple // Allow multiple file selection
                                onChange={handleFileInputChange1}
                              />
                            </Button>
                            {selectedFiles.length > 1 && imagePercent >= 100 ? (
                              <span
                                className="ml-5 font-semibold"
                                style={{ color: "green" }}
                              >
                                {selectedFiles.length} files
                              </span>
                            ) : (
                              imagePercent >= 100 && (
                                <span
                                  className="ml-5 font-semibold"
                                  style={{ color: "green" }}
                                >
                                  {selectedFiles.length} file
                                </span>
                              )
                            )}

                            <>
                              {imagePercent < 100 && hideUpload ? (
                                <span
                                  className="ml-5 font-semibold"
                                  style={{
                                    color: imagePercent < 100 ? "red" : "green",
                                  }}
                                >
                                  Upload {imagePercent}%
                                </span>
                              ) : (
                                <span
                                  className="ml-5 font-semibold"
                                  style={{ color: "green" }}
                                >
                                  {uploadMarketSuccessMsg}
                                </span>
                              )}
                            </>
                          </>
                        )}
                      </label>
                      {/* Display the uploaded code for individual images */}
                      <div className="flex flex-wrap mt-5">
                        {selectedFiles.map((file, index) => (
                          <div key={index} className="mr-10 mb-3">
                            <img src={file} className="h-40 w-40" />
                            <button
                              className="mt-2"
                              type="button"
                              onClick={() => handleDeleteClick(index)}
                            >
                              <p className="text-gray-400 hover:text-gray-700">
                                <DeleteIcon />
                              </p>
                            </button>
                          </div>
                        ))}
                      </div>
                      {/* {selectedFiles.length >= 4 && (
        <div className="flex w-30 p-1.5 float-right">
          <button
            onClick={clearAll}
            className="flex flex-wrap justify-center w-full px-4 py-1 font-medium text-md border border-red-900  rounded-md shadow-button bg-white hover:bg-red-900 hover:text-white"
          >
            <p>Clear All</p>
          </button>
        </div>
      )} */}
                    </div>
                    <Box
                      sx={{
                        mt: 1,
                        display: "flex",
                        gap: 1,
                        flexDirection: { xs: "column", sm: "row-reverse" },
                      }}
                    >
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#0066A2",
                          color: "white",
                        }}
                        onClick={onFinish}
                      >
                        Submit
                      </Button>
                      <Button
                        variant="outlined"
                        color="neutral"
                        onClick={() => {
                          setOpen2(false);
                          preFill();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="outlined"
                        color="neutral"
                        style={{
                          backgroundColor: "red", // Change color to red
                          color: "white",
                        }}
                        onClick={clearAll}
                      >
                        Clear all
                      </Button>
                    </Box>
                  </ModalDialog>
                </Modal>
              </React.Fragment>

              {/* ---------------------------------------key areas---------------------------------------------- */}

              {/* <React.Fragment>
                    <Modal open={open3}>
                    <Box style = {style}>
                      <ModalDialog
                        aria-labelledby="nested-modal-title"
                        aria-describedby="nested-modal-description"
                        sx= {style}
                     
                      >
                        <div class="w-full md:w-auto p-2 mb-4 mt-5">
                          <h2 class="text-indigo-900 text-lg font-semibold">
                          Interested Domains
                          </h2>
                        </div>
                
                        <Autocomplete
                          multiple
                          id="checkboxes-tags-demo"
                          options={Keyareas}
                          limitTags={4}
                          disableCloseOnSelect
                          name={(option) => option.title}
                          getOptionLabel={(option) => option.title}
                          value={selectedkeyArea}
    
                          onChange={(event, newValue) =>
                            handleKeyAreaSelection(newValue)
                          }
                          style={{ width: "100%", marginTop: "10px" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Interested Domains"
                              variant="outlined"
                              required = {keyAreas.length >0 ?false :true}
                              

                            />
                          )}
                          PaperProps={{
                            style: {
                              ...columnStyles,
                              display: "flex",
            flexWrap: "wrap",
                            },
                          }}
                          ListboxProps={{
                            style: {
                              ...columnStyles,
                            },
                          }}
                          renderOption={(props, option, { selected }) => {
                            const isOptionSelected = (
                              selectedkeyArea || []
                            ).some(
                              (defaultOption) =>
                                defaultOption.title === option.title
                            );
                            return (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 2 }}
                                  checked={selected || isOptionSelected}
                                />
                                {option.title}
                              </li>
                            );
                          }}
                        />
                      

                        <Box
                          sx={{
                            mt: 1,
                            display: "flex",
                            gap: 1,
                            flexDirection: { xs: "column", sm: "row-reverse" },
                          }}
                        >
                          <Button
                            type="submit"
                            variant="contained"
                            style={{
                              backgroundColor: "#0066A2",
                              color: "white",
                            }}
                            onClick={onFinish}
                          >
                            Submit
                          </Button>
                          <Button
                            variant="outlined"
                            color="neutral"
                            onClick={() => {setOpen3(false);preFill()}}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </ModalDialog>
                  </Box>
                    </Modal>
                  </React.Fragment> */}

              <React.Fragment>
                <Modal open={open3}>
                  <Box style={style}>
                    <ModalDialog
                      aria-labelledby="nested-modal-title"
                      aria-describedby="nested-modal-description"
                      sx={style}
                    >
                      <div class="w-full md:w-auto p-2 mb-4 mt-5">
                        <h2 class="text-[#0F3D79] text-lg font-semibold">
                          Interested Domains
                        </h2>
                      </div>

                      <form
                        onSubmit={(event) => {
                          event.preventDefault();
                          if (selectedkeyArea.length > 0) {
                            // Change the condition to 1
                            onFinish();
                          } else {
                            // Handle the case when less than 2 fields are selected
                            toast.warning(
                              "Please select atleast one Interested Domains",
                              {
                                position: "top-center",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeButton: false,
                                closeOnClick: false,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                              }
                            );
                          }
                        }}
                      >
                        <Autocomplete
                          multiple
                          id="checkboxes-tags-demo"
                          options={Keyareas}
                          limitTags={4}
                          disableCloseOnSelect
                          name={(option) => option.title}
                          getOptionLabel={(option) => option.title}
                          value={selectedkeyArea}
                          onChange={(event, newValue) =>
                            handleKeyAreaSelection(newValue)
                          }
                          style={{ width: "100%", marginTop: "10px" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Interested Domains"
                              variant="outlined"
                            />
                          )}
                          PaperProps={{
                            style: {
                              ...columnStyles,
                              display: "flex",
                              flexWrap: "wrap",
                            },
                          }}
                          ListboxProps={{
                            style: {
                              ...columnStyles,
                            },
                          }}
                          renderOption={(props, option, { selected }) => {
                            const isOptionSelected = (
                              selectedkeyArea || []
                            ).some(
                              (defaultOption) =>
                                defaultOption.title === option.title
                            );
                            return (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 2 }}
                                  checked={selected || isOptionSelected}
                                />
                                {option.title}
                              </li>
                            );
                          }}
                        />

                        <Box
                          sx={{
                            mt: 1,
                            display: "flex",
                            gap: 1,
                            flexDirection: {
                              xs: "column",
                              sm: "row-reverse",
                            },
                          }}
                        >
                          <Button
                            type="submit"
                            variant="contained"
                            style={{
                              backgroundColor: "#0066A2",
                              color: "white",
                            }}
                          >
                            Submit
                          </Button>
                          <Button
                            variant="outlined"
                            color="neutral"
                            onClick={() => {
                              setOpen3(false);
                              preFill();
                            }}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </form>
                    </ModalDialog>
                  </Box>
                </Modal>
              </React.Fragment>

              {/* -------------------------------------Description-------------------------------------------------- */}
              <React.Fragment>
                <Modal open={open}>
                  <ModalDialog
                    aria-labelledby="nested-modal-title"
                    aria-describedby="nested-modal-description"
                    sx={(theme) => ({
                      maxWidth: {
                        xs: "90%",
                        sm: "500px",
                        md: "600px",
                        lg: "700px",
                      },
                      width: "100%",
                      [theme.breakpoints.only("xs")]: {
                        top: "unset",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        borderRadius: 0,
                        transform: "none",
                        maxWidth: "unset",
                      },
                    })}
                  >
                    {/* <h2 className="mb-5 font-semibold text-indigo-900"> */}
                    <h2
                      className="mb-5 font-semibold"
                      style={{ color: "#0F3D79" }}
                    >
                      About
                    </h2>
                    <ReactQuill
                      placeholder="Type here..........."
                      theme="snow"
                      value={text}
                      onChange={setText}
                      endDecorator={
                        <Box
                          sx={{
                            display: "flex",
                            gap: "var(--Textarea-paddingBlock)",
                            pt: "var(--Textarea-paddingBlock)",
                            borderTop: "1px solid",
                            borderColor: "divider",
                            flex: "auto",
                          }}
                        >
                          <Typography
                            level="body-xs"
                            sx={{
                              ml: "auto",
                              color: text.length < 1000 ? "black" : "red",
                              fontSize: "16px",
                            }}
                          >
                            {text.length < 1000
                              ? `${text.length} character(s)`
                              : characterLimit}
                          </Typography>
                        </Box>
                      }
                      sx={{
                        minWidth: 500,
                        fontWeight,
                        fontStyle: italic ? "italic" : "initial",
                      }}
                    />
                    <Box
                      sx={{
                        fontSize: "16px",
                        display: "flex",
                        flexDirection: "column",
                        minWidth: 500,
                        fontWeight: "bold", // Assuming you want bold font
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: "16px",
                          display: "flex",
                          gap: 2,
                          pt: 2,
                          borderTop: "1px solid",
                          borderColor: "divider",
                          flex: "auto",
                          mt: 2, // Adjust the margin top if needed
                        }}
                      >
                        <Typography
                          sx={{
                            ml: "auto",
                            color: text.length < 1000 ? "black" : "red",
                            fontSize: "16px", // Adjust the font size of the character count
                          }}
                        >
                          {text.length < 1500
                            ? `${text.length} character(s)`
                            : characterLimit}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        mt: 1,
                        display: "flex",
                        gap: 1,
                        flexDirection: { xs: "column", sm: "row-reverse" },
                      }}
                    >
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#0066A2",
                          color: "white",
                        }}
                        type="submit"
                        onClick={onFinish}
                      >
                        Submit
                      </Button>
                      <Button
                        variant="outlined"
                        color="neutral"
                        onClick={() => {
                          setOpen(false);
                          preFill();
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </ModalDialog>
                </Modal>
              </React.Fragment>
              {/* classification */}

              <React.Fragment>
                <Modal open={open5}>
                  <ModalDialog
                    aria-labelledby="nested-modal-title"
                    aria-describedby="nested-modal-description"
                    sx={(theme) => ({
                      [theme.breakpoints.only("xs")]: {
                        top: "unset",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        borderRadius: 0,
                        transform: "none",
                        maxWidth: "unset",
                      },
                    })}
                  >
                    <div className="flex items-center justify-center p-2">
                      <h2 className="text-[#0F3D79] text-lg font-semibold">
                        General Information
                      </h2>
                    </div>
                    <div class="mt-4">
                      {/* <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        placeholder="Classified As"
                        variant="standard"
                        size="small"
                        // value={classified}
                        value={formData.classified_as}
                        onChange={handleClass}
                      /> */}
                      <InputLabel className="w-32">Classification</InputLabel>
                      <Select
                        // required
                        name="job_type"
                        label="Classification"
                        size="medium"
                        value={classified}
                        onChange={(e) => setClassified(e.target.value)}
                        className="w-40 h-12"
                      >
                        {classification?.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <br />
                    </div>
                    <Box
                      sx={{
                        mt: 1,
                        display: "flex",
                        gap: 1,
                        flexDirection: { xs: "column", sm: "row-reverse" },
                      }}
                    >
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#0066A2",
                          color: "white",
                        }}
                        onClick={onFinish}
                      >
                        Submit
                      </Button>
                      <Button
                        variant="outlined"
                        color="neutral"
                        onClick={() => {
                          setOpen5(false);
                          // preFill();
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </ModalDialog>
                </Modal>
              </React.Fragment>
              {/* -------------------------------------Additional information-------------------------------------------------- */}
              <React.Fragment>
                <Modal open={open4}>
                  <ModalDialog
                    aria-labelledby="nested-modal-title"
                    aria-describedby="nested-modal-description"
                    sx={(theme) => ({
                      [theme.breakpoints.only("xs")]: {
                        top: "unset",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        borderRadius: 0,
                        transform: "none",
                        maxWidth: "unset",
                      },
                      width: "400px", // Adjust modal width for a more compact look
                      maxWidth: "90%", // Ensure it scales on small screens
                    })}
                  >
                    <div className="flex items-center justify-center p-4">
                      <Typography
                        variant="h6"
                        color="primary"
                        fontWeight="bold"
                      >
                        Additional Information
                      </Typography>
                    </div>

                    <Box p={2} className="flex justify-center">
                      <Typography
                        variant="body1"
                        color="primary"
                        fontWeight="semibold"
                      >
                        Upload Profile Image:
                      </Typography>
                      <Tooltip title={Text}>
                        {/* <p className="ml-2"> */}
                          <InfoOutlinedIcon />
                        {/* </p> */}
                      </Tooltip>
                    </Box>

                    <div className="ml-8 flex justify-center">
                      <label>
                        <Button
                          component="label"
                          variant="outlined"
                          color="neutral"
                          sx={{
                            marginTop: "2%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "8px",
                          }}
                        >
                          {authnamePic ? (
                            <>
                              <img
                                src={authnamePic}
                                alt="Profile"
                                className="w-20 h-20 rounded-full mb-2" // Smaller profile image
                              />
                              <Button
                                type="button"
                                onClick={handleDeleteClickLogo2}
                                sx={{ minWidth: 0, padding: 0 }}
                              >
                                <DeleteIcon  />
                              </Button>
                            </>
                          ) : (
                            <input
                              className="w-24"
                              type="file"
                              accept="image/*"
                              onChange={handleFileInputChange2}
                              // style={{  }}
                            />
                          )}
                        </Button>
                      </label>
                    </div>

                    {profilePercent < 100 && authnamePic ? (
                      <Typography
                        variant="body2"
                        color={profilePercent < 100 ? "error" : "success"}
                        sx={{ marginLeft: "8px", fontWeight: "bold" }}
                      >
                        Upload {profilePercent}%
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="success.main">
                        {uploadProfileSuccessMsg}
                      </Typography>
                    )}

                    <Box mt={2}>
                      <TextField
                        label="Authorized Person Name"
                        variant="standard"
                        fullWidth
                        size="small"
                        value={authname}
                        onChange={handleAuthName}
                        sx={{ marginBottom: 2 }}
                      />
                      <TextField
                        label="Designation"
                        variant="standard"
                        fullWidth
                        size="small"
                        value={desig}
                        onChange={handleDesign}
                        sx={{ marginBottom: 2 }}
                      />
                      <TextField
                        label="City"
                        variant="standard"
                        fullWidth
                        size="small"
                        value={ioiCity}
                        onChange={handleCity}
                        sx={{ marginBottom: 2 }}
                      />
                      <TextField
                        label="State"
                        variant="standard"
                        fullWidth
                        size="small"
                        value={ioiState}
                        onChange={handleState}
                        sx={{ marginBottom: 2 }}
                      />
                      <TextField
                        label="Country"
                        variant="standard"
                        fullWidth
                        size="small"
                        value={ioiCountry}
                        onChange={handleCountry}
                        sx={{ marginBottom: 2 }}
                      />
                      <TextField
                        label="Website"
                        variant="standard"
                        fullWidth
                        size="small"
                        value={linkWeb}
                        onChange={handleLinkWeb}
                        sx={{ marginBottom: 2 }}
                      />
                    </Box>

                    <Box
                      sx={{
                        mt: 3,
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: 2,
                        flexDirection: { xs: "column", sm: "row" },
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onFinish}
                        sx={{ width: { xs: "100%", sm: "auto" } }}
                      >
                        Submit
                      </Button>
                      <Button
                        variant="outlined"
                        color="neutral"
                        onClick={() => {
                          setOpen4(false);
                          preFill();
                        }}
                        sx={{ width: { xs: "100%", sm: "auto" } }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </ModalDialog>
                </Modal>
              </React.Fragment>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
