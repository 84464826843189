import { useDispatch, useSelector } from "react-redux";
import { Outlet,Navigate, useNavigate } from "react-router-dom";
import { fetchIOIProfile, ioiuserSelector } from "../api/ioiUser";
import { useEffect } from "react";

const PrivateRoutes = ()=>{

    const {isAuthenticate,token,user} = useSelector(ioiuserSelector)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(()=>{
        if(token)
        dispatch(fetchIOIProfile(token))
    },[isAuthenticate])
    
    return (
        isAuthenticate?<Outlet/>:<Navigate to ="/"/>
    )
}

export default PrivateRoutes;