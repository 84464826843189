import { Modal, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PhoneInputCountryFlag from "react-phone-number-input";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../shared/firebaseStorage";
import { useNavigate } from "react-router-dom";
import { fetchIOIProfile, ioiuserSelector, updateIOI } from "../../api/ioiUser";
import { useDispatch, useSelector } from "react-redux";
import { ModalDialog } from "@mui/joy";
import "react-phone-number-input/style.css";
import "../../index.css";
import { makeStyles } from "@material-ui/core/styles";
import { isValidPhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";

let recaptcha = null;
function recaptaVerifier(number) {
  if (recaptcha == null) {
    recaptcha = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
      "expired-callback": () => {},
    });
    recaptcha.render();
  }

  return signInWithPhoneNumber(auth, number, recaptcha);
}

function VerifyPhone() {
  const useStyles = makeStyles({
    input: {
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  });
  const classes = useStyles();
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [flag, setFlag] = useState(false);
  const [confirm, setConfirm] = useState("");
  const [error, setError] = useState("");
  const [verified, setVerified] = useState(false);
  const navigate = useNavigate();
  const { token, user } = useSelector(ioiuserSelector);
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const [status, setStatus] = useState(false);
  const [disable, setDisable] = useState(false);

  const handlePhoneNumberChange = (value) => {
    setError("");
    setNumber(value);
  };

  useEffect(() => {
    dispatch(fetchIOIProfile(token));
  }, [token]);

  useEffect(() => {
    if (user?.profile_completion?.status && user?.phone?.verified)
      setStatus(true);
    else if (!user?.phone?.verified) navigate("/verify-phone");
    else if (!user?.profile_completion?.status) navigate("/ioiprofile");
  }, [user]);

  useEffect(() => {
    if (status) navigate("/home");
  }, [status]);

  const getOtp = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const response = await recaptaVerifier(number);
      setConfirm(response);
      setFlag(true);
    } catch (err) {
      ////console.log(err.message);
      if (err.message == "Firebase: TOO_SHORT (auth/invalid-phone-number).")
        setError("Invalid Phone number");
      else if (err.message == "Firebase: TOO_LONG (auth/invalid-phone-number).")
        setError("Invalid Phone number");
      else if (err.message == "Firebase: Error (auth/too-many-requests).")
        setError(
          "Verification blocked due to too many attempts.try again after 24hours"
        );
      else if (err.message == "Invalid format. (auth/invalid-phone-number).")
        setError("Invalid Phone number");
      // recaptcha.destroyed = true;
      // recaptcha.recaptcha.reset();
      // window.location.reload()
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await confirm.confirm(otp);
      setOtp("");
      setVerified(true);
      setFlag(false);
      const data = {
        phone: {
          phone_number: number,
          verified: true,
        },
      };
      setTimeout(() => {
        toast.success("Phone number verification Successful.");
      }, 2500);
      dispatch(updateIOI(user?._id, data, token));
      setOpen(false);
      navigate("/ioiprofile");
    } catch (error) {
      ////console.log(error.message);
      if (error.message) setError("Invalid OTP");
      setOtp("");
      // recaptcha.destroyed = true;
      // recaptcha.recaptcha.reset();
      setFlag(false);
      // window.location.reload()
    }
  };

  return (
    <>
      <Modal open={open} style={{ fontFamily: "Lato" }}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ maxWidth: 500 }}
        >
          <form onSubmit={getOtp}>
            <div className="p-4">
              <Typography variant="h6" sx={{ mb: 2 }}>
                Phone Number:
              </Typography>

              <PhoneInputCountryFlag
                defaultCountry="IN"
                international="false"
                rules={{ required: true }}
                value={number}
                onChange={handlePhoneNumberChange}
                placeholder="Enter Phone number"
                containerStyle={{
                  marginBottom: "1rem",
                  display: "none",
                }}
              />
              <div>
                {/* <TextField type = "tel" name = "number" max={10} min={1} onChange={handlePhoneNumberChange} placeholder= 'Enter Phone number' 
          InputProps={{
    classes: {
      input: 'no-spinners' // Assign a custom class to the input element
    }
  }}/> */}

                {/* <TextField className={classes.input} />; */}
              </div>
              <button
                class=" mt-5 py-2 px-2 text-white font-medium rounded-xl  bg-blue-600 hover:bg-blue-700"
                type="submit"
              >
                {" "}
                Generate OTP
              </button>
              <div id="recaptcha-container" style={{ display: "none" }}></div>

              {verified && (
                <Typography variant="body2" sx={{ mt: 2, color: "green" }}>
                  Verified
                </Typography>
              )}
            </div>
          </form>

          {error.length > 0 && (
            <Typography variant="body2" sx={{ mt: 2, color: "red" }}>
              {error}
            </Typography>
          )}

          <form
            onSubmit={verifyOtp}
            style={{ display: flag ? "block" : "none" }}
            className="p-4"
          >
            <TextField
              id="outlined-basic"
              label="Enter OTP"
              variant="outlined"
              size="small"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              sx={{ mb: 2, width: "100%" }}
            />

            <button
              class=" py-2 px-2 text-white font-medium rounded-xl  bg-blue-600 hover:bg-blue-700"
              type="submit"
            >
              {" "}
              Verify OTP
            </button>
          </form>
        </ModalDialog>
      </Modal>
    </>
  );
}

export default VerifyPhone;
