// import React, { useEffect, useState } from "react";
// import { Box } from "@material-ui/core";
// import "./Explore.css";
// import WorkIcon from "@mui/icons-material/Work";
// import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
// import { Button, Tooltip } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import { deleteJob, fetchPostedJobs, jobsSelector } from "../../api/jobs";
// import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
// import DeleteIcon from "@mui/icons-material/Delete";
// import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import Modal from "@mui/material/Modal";
// import { fetchIOIProfile, ioiuserSelector } from "../../api/ioiUser";
// import AddIcon from "@material-ui/icons/Add";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import { FormControl } from "@mui/material";
// import { Autocomplete } from "@mui/joy";
// import { TextField, IconButton } from "@material-ui/core";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
// import FilterIcon from "@mui/icons-material/FilterList";
// import image2 from "../../images/logo.png";
// import image3 from "../../images/bharcimg.png";
// import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; // Import a PDF icon
// import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import { createExplore } from "../../api/expore";
// import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import { toast } from "react-toastify";
// import { nanoid } from "@reduxjs/toolkit";
// import storage from "../../shared/firebaseStorage";

// function CreatEvent() {
//   const { pathname } = useLocation();
//   const dispatch = useDispatch();
//   const nav = useNavigate();
//   const { user, token } = useSelector(ioiuserSelector);
//   const [document, setDocument] = useState("");
//   const [imagePercent, setImagePercent] = useState(0);
//   const [uploadSuccessMsg, setUploadSuccessMsg] = useState("");

//   const [events, setEvents] = useState({
//     event_name: "",
//     date: "",
//     document: "Active",
//   });

//   const handleFileInputChange = (event) => {
//     setImagePercent(0);
//     const newSelectedFile = event.target.files[0];
//     if (newSelectedFile) {
//       const isJpgOrPng =
//         newSelectedFile.type === "image/jpeg" ||
//         newSelectedFile.type === "image/png" ||
//         newSelectedFile.type === "image/webp";

//       if (!isJpgOrPng) {
//         toast.warning("You can only upload JPG/PNG/WEBP file!", {
//           position: "top-center",
//           autoClose: 1000,
//           hideProgressBar: false,
//           closeButton: false,
//           closeOnClick: false,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "light",
//         });
//       }
//       const isLt100KB = newSelectedFile.size / 1024 < 100;
//       if (!isLt100KB) {
//         toast.warning("Image size must be less than 100kb", {
//           position: "top-center",
//           autoClose: 2000,
//           hideProgressBar: false,
//           closeButton: false,
//           closeOnClick: false,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "light",
//         });
//       }
//       if (isJpgOrPng && isLt100KB) {
//         const storageRef = ref(
//           storage,
//           `/IOI Profile Completion/${nanoid() + newSelectedFile.name}`
//         );
//         ////console.log(storageRef);
//         const uploadFile = uploadBytesResumable(storageRef, newSelectedFile);
//         uploadFile.on(
//           "state_changed",
//           (snapshot) => {
//             const percent = Math.round(
//               (snapshot.bytesTransferred / snapshot.totalBytes) * 100
//             );
//             // update progress
//             setImagePercent(percent);
//             if (percent == 100)
//               setUploadSuccessMsg("Image Uploaded Successfully.");
//           },
//           (err) => {
//             console.error(err);
//           },
//           () => {
//             // download url
//             getDownloadURL(uploadFile.snapshot.ref).then((url) => {
//               setDocument(url);
//               ////console.log(Logo, "setlogo");
//             });
//           }
//         );
//       }
//     }
//   };

//   const handleChange = (e) => {
//     setEvents({ ...events, [e.target.name]: e.target.value });
//   };

//   // Handle form submission

//   const submitHandler = (e) => {
//     console.log("creating events.......");
//     e.preventDefault();
//     const { event_name, date } = events;
//     console.log(events, "events");

//     if (event_name && date && document) {
//       const eventInfo = {
//         ...events,
//         document,
//         explore_posted_by_ioi: user._id,
//       };

//       dispatch(createExplore(eventInfo)); // Dispatch the createPublish action
//       nav(-1); // Navigate back after successful submission
//     } else {
//       toast.warning("Please fill all the fields and upload a document", {
//         position: "top-center",
//         autoClose: 1000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//       });
//     }
//   };

//   return (
//     <section class="py-8" style={{ fontFamily: "Lato" }}>
//       <div class="container px-4 mx-auto">
//         <div class="flex flex-wrap -mx-3">
//           <div class="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
//             <div class="w-full mb-6">
//               <div class="relative p-6 text-center bg-gray-100 rounded-xl">
//                 <img
//                   class="block mx-auto mb-5 w-28 h-28 rounded-full"
//                   src={
//                     user?.profile_completion?.logo
//                       ? user?.profile_completion?.logo
//                       : "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"
//                   }
//                   alt=""
//                 />
//                 <h4 class="text-lg text-[#0F3D79] font-bold mb-3">
//                   {user?.name}
//                 </h4>
//                 <div class="flex items-center justify-center mb-6">
//                   <p class="text-md font-semibold text-gray-500">
//                     {user?.sub_category}
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
//               <NavLink to="/Conferences">
//                 <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
//                   <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
//                     <WorkIcon />
//                   </div>
//                   <div>
//                     <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
//                       Conferences
//                     </h4>
//                   </div>
//                 </div>
//               </NavLink>
//               <NavLink to="/Competitions">
//                 <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
//                   <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
//                     <WorkIcon />
//                   </div>
//                   <div>
//                     <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
//                       Competitions
//                     </h4>
//                   </div>
//                 </div>
//               </NavLink>
//               <NavLink to="/Otherevents">
//                 <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
//                   <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
//                     <WorkIcon />
//                   </div>
//                   <div>
//                     <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
//                       Other Events
//                     </h4>
//                   </div>
//                 </div>
//               </NavLink>
//               <NavLink to="/Conferences">
//                 <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
//                   <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
//                     <WorkIcon />
//                   </div>
//                   <div>
//                     <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
//                       Ads
//                     </h4>
//                   </div>
//                 </div>
//               </NavLink>
//               <NavLink to="/Myevents">
//                 <div
//                   class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
//                   style={{
//                     backgroundColor: pathname.includes("Myevents")
//                       ? "#CEDDF6"
//                       : "#F3F4F6",
//                   }}
//                 >
//                   <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
//                     <WorkIcon />
//                   </div>
//                   <div>
//                     <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
//                       My Events
//                     </h4>
//                   </div>
//                 </div>
//               </NavLink>
//             </div>
//           </div>
//           <div className="w-full lg:w-[78%] px-3">
//             <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
//               <div className="md:max-w-6xl mx-auto">
//                 {/* Header */}
//                 <div className="my-4 mx-0">
//                   <h1 className="text-lg font-semibold text-[#0F3D79]">
//                     Create event
//                   </h1>
//                 </div>
//                 <form onSubmit={submitHandler}>
//                   <div className="mb-6 relative">
//                     <label
//                       className="block text-gray-700 text-sm font-bold mb-2"
//                       htmlFor="domain"
//                     >
//                       Event type
//                     </label>
//                     <div className="relative">
//                       <select
//                         id="domain"
//                         className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline pr-8"
//                       >
//                         <option>Select the event type</option>
//                         {/* Add more options here */}
//                       </select>
//                       <svg
//                         className="absolute right-3 top-1/2 transform -translate-y-1/2 w-8 h-8 text-[#0F3D79] pointer-events-none"
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                         aria-hidden="true"
//                       >
//                         <path
//                           fillRule="evenodd"
//                           d="M6.293 9.293a1 1 0 011.414 0L10 10.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
//                           clipRule="evenodd"
//                         />
//                       </svg>
//                     </div>
//                   </div>

//                   <div className="mb-6">
//                     <label
//                       className="block text-gray-700 text-sm font-bold mb-2"
//                       htmlFor="topicName"
//                     >
//                       Event name
//                     </label>
//                     <input
//                       id="event_name"
//                       name="event_name"
//                       value={events.event_name}
//                       onChange={handleChange}
//                       type="text"
//                       placeholder="Enter event name"
//                       className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                     />
//                   </div>

//                   <div className="mb-6">
//                     <label
//                       className="block text-gray-700 text-sm font-bold mb-2"
//                       htmlFor="date"
//                     >
//                       Date
//                     </label>
//                     <div className="relative">
//                       <input
//                         id="date"
//                         name="date"
//                         value={events.date}
//                         onChange={handleChange}
//                         type="date"
//                         className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                       />
//                       <span className="absolute inset-y-0 right-0 flex items-center pr-3"></span>
//                     </div>
//                   </div>

// <div className="mb-6">
//   <label
//     className="block text-gray-700 text-sm font-bold mb-2"
//     htmlFor="upload"
//   >
//     Upload a document / image
//   </label>
//   <div className="relative flex items-center border-dashed border-2 border-gray-300 rounded p-4">
//     <input
//       type="file"
//       accept="image/*"
//       onChange={handleFileInputChange}
//       className="absolute inset-0 opacity-0 cursor-pointer"
//     />
//     <span className="flex-1 text-gray-700">
//       Select the document or image to upload
//     </span>
//     <CloudUploadIcon className="w-6 h-6 text-blue-500" />
//     {imagePercent > 0 && <p>Uploading: {imagePercent}%</p>}
//     {uploadSuccessMsg && <p>{uploadSuccessMsg}</p>}
//   </div>
// </div>

//                   <div className="flex items-center justify-between">
//                     <button
//                       className="bg-[#002357] hover:bg-blue-700 text-white font-semi py-2 px-4 rounded focus:outline-none focus:shadow-outline"
//                       type="button"
//                     >
//                       Create an event
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default CreatEvent;

import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import "./Explore.css";
import WorkIcon from "@mui/icons-material/Work";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createExplore } from "../../api/expore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";
import { nanoid } from "@reduxjs/toolkit";
import storage from "../../shared/firebaseStorage";
import { ioiuserSelector } from "../../api/ioiUser";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ExploreIcon from "@mui/icons-material/Explore";

function CreateEvent() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(ioiuserSelector);
  const [document, setDocument] = useState("");
  const [imagePercent, setImagePercent] = useState(0);
  const [uploadSuccessMsg, setUploadSuccessMsg] = useState("");
  const [eventCategory, setEventCategory] = useState({ event_type: "" });

  const [events, setEvents] = useState({
    event_name: "",
    date: "",
    document: "Active",
  });

  const handleCategory = (e) => {
    setEventCategory(e.target.value); // Changed to handle string value
  };

  // Handle file input change
  const handleFileInputChange = (event) => {
    setImagePercent(0);
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const isJpgOrPng =
        selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/png" ||
        selectedFile.type === "image/webp";

      if (!isJpgOrPng) {
        toast.warning("You can only upload JPG/PNG/WEBP file!", {
          position: "top-center",
          autoClose: 1000,
          theme: "light",
        });
      }

      const isLt5MB = selectedFile.size / 1024 / 1024 < 5;

      if (!isLt5MB) {
        toast.warning("Image size must be less than 5 MB", {
          position: "top-center",
          autoClose: 2000,
          theme: "light",
        });
      }
      if (isJpgOrPng && isLt5MB) {
        const storageRef = ref(
          storage,
          `/IOI Profile Completion/${nanoid() + selectedFile.name}`
        );
        const uploadFile = uploadBytesResumable(storageRef, selectedFile);
        uploadFile.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setImagePercent(percent);
            if (percent === 100)
              setUploadSuccessMsg("Image Uploaded Successfully.");
          },
          (err) => {
            console.error(err);
          },
          () => {
            getDownloadURL(uploadFile.snapshot.ref).then((url) => {
              setDocument(url);
            });
          }
        );
      }
    }
  };

  // Handle form field changes
  const handleChange = (e) => {
    setEvents({ ...events, [e.target.name]: e.target.value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const { event_name, date } = events;

    if (event_name && date && document) {
      const eventInfo = {
        ...events,
        document,
        event_type: eventCategory,
        explore_posted_by_ioi: user._id,
      };

      await dispatch(createExplore(eventInfo));
      setTimeout(() => {
        navigate(-1); // Navigate back after the delay
      }, 3000); // 3 seconds delay
    } else {
      // Show warning toast if fields are not filled
      toast.warning("Please fill all the fields", {
        position: "top-center",
        autoClose: 1000, // Display for 1 second
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
  };

  console.log(eventCategory, "event category");

  // Handle form submission

  return (
    <section className="py-8" style={{ fontFamily: "Lato" }}>
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap -mx-3">
          <div className="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
            <div className="w-full mb-6">
              <div className="relative p-6 text-center bg-gray-100 rounded-xl">
                <img
                  className="block mx-auto mb-5 w-28 h-28 rounded-full"
                  src={
                    user?.profile_completion?.logo
                      ? user?.profile_completion?.logo
                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  }
                  alt=""
                />
                <h4 className="text-lg text-[#0F3D79] font-bold mb-3">
                  {user?.name}
                </h4>
                <div className="flex items-center justify-center mb-6">
                  <p className="text-md font-semibold text-gray-500">
                    {user?.sub_category}
                  </p>
                </div>
              </div>
            </div>
            <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
              <NavLink to="/Conferences">
                <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <WorkIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Conferences
                    </h4>
                  </div>
                </div>
              </NavLink>
              <NavLink to="/Competitions">
                <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <WorkIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Competitions
                    </h4>
                  </div>
                </div>
              </NavLink>
              <NavLink to="/Otherevents">
                <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <WorkIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Other Events
                    </h4>
                  </div>
                </div>
              </NavLink>
              <NavLink to="/Ads">
                <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <WorkIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Ads
                    </h4>
                  </div>
                </div>
              </NavLink>
              <NavLink to="/Myevents">
                <div
                  class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                  style={{
                    backgroundColor: pathname.includes("Myevents")
                      ? "#CEDDF6"
                      : "#F3F4F6",
                  }}
                >
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <WorkIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      My Events
                    </h4>
                  </div>
                </div>
              </NavLink>
              <NavLink to="/SavedAds">
                <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <WorkIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Saved Ads
                    </h4>
                  </div>
                </div>
              </NavLink>
              <NavLink to="/MyAds">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                       My Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
            </div>
          </div>

          <div className="w-full lg:w-[78%] px-3">
            <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
              <div className="md:max-w-6xl mx-auto">
                {/* Header */}
                <div className="my-4 mx-0">
                  <h1 className="text-lg font-semibold text-[#0F3D79]">
                    Create event
                  </h1>
                </div>
                <form onSubmit={submitHandler}>
                  <div className="mb-6 relative">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="domain"
                    >
                      Event type
                    </label>
                    <div className="relative">
                      <select
                        value={eventCategory}
                        onChange={handleCategory}
                        id="domain"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline pr-8"
                      >
                        <option>Select the event type</option>
                        <option>Conferences</option>
                        <option>Competitions</option>
                        <option>Other events</option>
                        {/* Add more options here */}
                      </select>
                      <svg
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 w-8 h-8 text-[#0F3D79] pointer-events-none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6.293 9.293a1 1 0 011.414 0L10 10.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>

                  <div className="mb-6 relative">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="event_name"
                    >
                      Event name
                    </label>
                    <input
                      id="event_name"
                      name="event_name"
                      value={events.event_name}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter event name"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>

                  <div className="mb-6">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="date"
                    >
                      Event date
                    </label>
                    <input
                      id="date"
                      name="date"
                      value={events.date}
                      onChange={handleChange}
                      type="date"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>

                  <div className="mb-6">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="upload"
                    >
                      Upload a document / image
                    </label>
                    <div className="relative flex items-center border-dashed border-2 border-gray-300 rounded p-4">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileInputChange}
                        className="absolute inset-0 opacity-0 cursor-pointer"
                      />
                      <span className="flex-1 text-gray-700">
                        Select the document or image to upload
                      </span>
                      <CloudUploadIcon className="w-6 h-6 text-blue-500" />
                      {imagePercent > 0 && <p>Uploading: {imagePercent}%</p>}
                      {uploadSuccessMsg && <p>{uploadSuccessMsg}</p>}
                    </div>
                  </div>

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="w-full py-2"
                  >
                    Submit
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreateEvent;

// import React, { useState } from "react";
// import { Box } from "@material-ui/core";
// import "./Explore.css";
// import WorkIcon from "@mui/icons-material/Work";
// import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import { Button } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import { createExplore } from "../../api/expore";
// import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import { toast } from "react-toastify";
// import { nanoid } from "@reduxjs/toolkit";
// import storage from "../../shared/firebaseStorage";
// import { ioiuserSelector } from "../../api/ioiUser";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// function CreateEvent() {
//   const { pathname } = useLocation();
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { user } = useSelector(ioiuserSelector);
//   const [document, setDocument] = useState("");
//   const [imagePercent, setImagePercent] = useState(0);
//   const [uploadSuccessMsg, setUploadSuccessMsg] = useState("");
//   const [eventCategory, setEventCategory] = useState(""); // Changed to string

//   const [events, setEvents] = useState({
//     event_name: "",
//     date: "",
//     document: "Active",
//   });

//   // Handle event category selection
//   const handleCategory = (e) => {
//     setEventCategory(e.target.value); // Changed to handle string value
//   };

//   // Handle file input change
//   const handleFileInputChange = (event) => {
//     setImagePercent(0);
//     const selectedFile = event.target.files[0];
//     if (selectedFile) {
//       const isJpgOrPng =
//         selectedFile.type === "image/jpeg" ||
//         selectedFile.type === "image/png" ||
//         selectedFile.type === "image/webp";

//       if (!isJpgOrPng) {
//         toast.warning("You can only upload JPG/PNG/WEBP file!", {
//           position: "top-center",
//           autoClose: 1000,
//           theme: "light",
//         });
//         return;
//       }

//       const isLt100KB = selectedFile.size / 1024 < 100;
//       if (!isLt100KB) {
//         toast.warning("Image size must be less than 100KB", {
//           position: "top-center",
//           autoClose: 2000,
//           theme: "light",
//         });
//         return;
//       }

//       if (isJpgOrPng && isLt100KB) {
//         const storageRef = ref(
//           storage,
//           `/IOI Profile Completion/${nanoid() + selectedFile.name}`
//         );
//         const uploadFile = uploadBytesResumable(storageRef, selectedFile);
//         uploadFile.on(
//           "state_changed",
//           (snapshot) => {
//             const percent = Math.round(
//               (snapshot.bytesTransferred / snapshot.totalBytes) * 100
//             );
//             setImagePercent(percent);
//             if (percent === 100) {
//               setUploadSuccessMsg("Image Uploaded Successfully.");
//             }
//           },
//           (err) => {
//             console.error(err);
//           },
//           () => {
//             getDownloadURL(uploadFile.snapshot.ref).then((url) => {
//               setDocument(url);
//             });
//           }
//         );
//       }
//     }
//   };

//   // Handle form field changes
//   const handleChange = (e) => {
//     setEvents({ ...events, [e.target.name]: e.target.value });
//   };

//   const submitHandler = async (e) => {
//     e.preventDefault();
//     const { event_name, date } = events;

//     if (event_name && date && document) {
//       const eventInfo = {
//         ...events,
//         document,
//         event_type: eventCategory,
//         explore_posted_by_ioi: user._id,
//       };

//       try {
//         // Dispatch action to create event
//         await dispatch(createExplore(eventInfo));

//         // Show success toast
//         const toastId = toast.success("Event Added Successfully", {
//           position: "top-center",
//           autoClose: false, // Keep toast open until manually closed
//           hideProgressBar: true,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           theme: "light",
//         });

//         // Wait before navigating
//         setTimeout(() => {
//           toast.dismiss(toastId); // Dismiss success toast
//           navigate(-1); // Navigate back to the previous page
//         }, 3000); // 3 seconds delay
//       } catch (error) {
//         // Show error toast
//         toast.error("Failed to create event. Please try again later.", {
//           position: "top-center",
//           autoClose: false, // Keep error toast visible until manually dismissed
//           hideProgressBar: true,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           theme: "light",
//         });

//         // Wait for a specified time, then navigate back
//         setTimeout(() => {
//           navigate(-1); // Navigate back to the previous page after delay
//         }, 3000); // Adjust the delay as needed (3 seconds in this example)
//       }
//     } else {
//       // Show warning toast if fields are not filled
//       toast.warning("Please fill all the fields", {
//         position: "top-center",
//         autoClose: 1000, // Display for 1 second
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         theme: "light",
//       });
//     }
//   };

//   return (
//     <section className="py-8" style={{ fontFamily: "Lato" }}>
//       <div className="container px-4 mx-auto">
//         <div className="flex flex-wrap -mx-3">
//           <div className="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
//             <div className="w-full mb-6">
//               <div className="relative p-6 text-center bg-gray-100 rounded-xl">
//                 <img
//                   className="block mx-auto mb-5 w-28 h-28 rounded-full"
//                   src={
//                     user?.profile_completion?.logo
//                       ? user?.profile_completion?.logo
//                       : "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"
//                   }
//                   alt="Profile"
//                 />
//                 <h4 className="text-lg text-[#0F3D79] font-bold mb-3">
//                   {user?.name}
//                 </h4>
//                 <div className="flex items-center justify-center mb-6">
//                   <p className="text-md font-semibold text-gray-500">
//                     {user?.sub_category}
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div className="py-5 bg-gray-100 rounded-xl overflow-hidden">
//               <NavLink to="/Conferences">
//                 <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
//                   <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
//                     <WorkIcon />
//                   </div>
//                   <div>
//                     <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
//                       Conferences
//                     </h4>
//                   </div>
//                 </div>
//               </NavLink>
//               <NavLink to="/Competitions">
//                 <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
//                   <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
//                     <WorkIcon />
//                   </div>
//                   <div>
//                     <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
//                       Competitions
//                     </h4>
//                   </div>
//                 </div>
//               </NavLink>
//               <NavLink to="/Otherevents">
//                 <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
//                   <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
//                     <WorkIcon />
//                   </div>
//                   <div>
//                     <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
//                       Other Events
//                     </h4>
//                   </div>
//                 </div>
//               </NavLink>
//             </div>
//           </div>

//           <div className="w-full lg:w-[78%] px-3">
//             <Box
//               component="form"
//               onSubmit={submitHandler}
//               noValidate
//               autoComplete="off"
//               className="bg-gray-100 p-8 rounded-xl shadow-lg"
//             >
//               <h2 className="text-2xl font-semibold text-[#0F3D79] mb-6 text-center">
//                 Create New Event
//               </h2>
//               <div className="mb-6">
//                 <label className="block text-sm font-semibold text-gray-700 mb-2">
//                   Event Name
//                 </label>
//                 <input
//                   type="text"
//                   name="event_name"
//                   value={events.event_name}
//                   onChange={handleChange}
//                   className="w-full px-3 py-2 border border-gray-300 rounded-md"
//                   placeholder="Enter event name"
//                 />
//               </div>
//               <div className="mb-6">
//                 <label className="block text-sm font-semibold text-gray-700 mb-2">
//                   Event Date
//                 </label>
//                 <input
//                   type="date"
//                   name="date"
//                   value={events.date}
//                   onChange={handleChange}
//                   className="w-full px-3 py-2 border border-gray-300 rounded-md"
//                 />
//               </div>
//               <div className="mb-6">
//                 <label className="block text-sm font-semibold text-gray-700 mb-2">
//                   Upload Document
//                 </label>
//                 <input
//                   type="file"
//                   accept="image/*"
//                   onChange={handleFileInputChange}
//                   className="w-full"
//                 />
//                 <p className="text-sm text-gray-500 mt-2">
//                   Upload image file (JPG/PNG/WEBP) up to 100KB
//                 </p>
//                 {imagePercent > 0 && (
//                   <div className="mt-2">
//                     <p className="text-sm font-semibold">
//                       Upload Progress: {imagePercent}%
//                     </p>
//                   </div>
//                 )}
//                 {uploadSuccessMsg && (
//                   <div className="mt-2">
//                     <p className="text-sm font-semibold text-green-500">
//                       {uploadSuccessMsg}
//                     </p>
//                   </div>
//                 )}
//               </div>
//               <div className="mb-6">
//                 <label className="block text-sm font-semibold text-gray-700 mb-2">
//                   Event Category
//                 </label>
//                 <select
//                   name="eventCategory"
//                   value={eventCategory}
//                   onChange={handleCategory}
//                   className="w-full px-3 py-2 border border-gray-300 rounded-md"
//                 >
//                   <option value="">Select Category</option>
//                   <option value="Conference">Conference</option>
//                   <option value="Competition">Competition</option>
//                   <option value="Other">Other</option>
//                 </select>
//               </div>
//               <Button
//                 type="submit"
//                 variant="contained"
//                 color="primary"
//                 startIcon={<CloudUploadIcon />}
//                 className="w-full"
//               >
//                 Submit
//               </Button>
//             </Box>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default CreateEvent;
