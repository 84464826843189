export const Keyareas = [
    { title: "Civil Engineering" },
    { title: "Electrical Engineering" },
    { title: "Mechanical Engineering" },
    { title: "Computer Science and Engineering" },
    { title: "Electronics and Communication Engineering" },
    { title: "Aeronautical Engineering" },
    { title: "Information Science and Engineering" },
    { title: "Automobile Engineering" },
    { title: "Environmental Engineering" },
    { title: "Chemical Engineering" },
    { title: "Textile Engineering" },
    { title: "Telecommunication Engineering" },
    { title: "Mining Engineering" },
    { title: "Biotechnology" },
    { title: "Biomedical Engineering" },
    { title: "Medical Electronics" },
    { title: "Instrumentation Technology" },
    { title: "Industrial and Production Engineering" },
    { title: "Industrial Engineering Management" },
    { title: "Manufacturing Science and Engineering" },
    { title: "Polymer Science and Technology" },
    { title: "Silk Technology" },
    { title: "Nano Technology" },
    { title: "Physics" },
    { title: "Chemistry" },
    { title: "Mathematics" },
    { title: "Artificial Intelligence" },
    { title: "Geology and Earth Sciences" },
    { title: "Renewable Energy" },
    { title: "Archaelogy" },
    { title: "Biology and Life Sciences" },
    { title: "Automation" },
    { title: "Agricultural Research" },
    { title: "Medical Research" },
    { title: "Horticultural Research" },
    { title: "Forest Science" },
    { title: "Robitics" },
    { title: "Social Sciences" },
    { title: "Arts and Literature" },
    { title: "Urban Planning and Architecture" },
    { title: "Law and Governance" },
    { title: "Statistics" },
    { title: "Business and Management" },
    { title: "Entrepreneurship" },
    { title: "Environmental studies" },
    { title: "Economics" },
    { title: "Cyber Security" },
    { title: "Basic Science" },
    { title: "Education" },
    { title: "Psychology and Behavioral Sciences" },
    { title: "Materials Science and Engineering" },
    { title: "Human Resource Development" },
    { title: "Aerospace Engineering" },
    { title: "Oceanic Sciences" },
    { title: "Meteorology" },
    { title: "Animal Sciences" },
    { title: "Ayurveda" },
    { title: "Organic Farming" },
    { title: "Resources Finding and  Exploration" },
    { title: "Cloud Technology" },
    { title: "Vaccine Developments" },
    { title: "Onocology" },
    { title: "Hydraluics" },
    { title: "Waste Management" },
    { title: "Space Technology" },
    { title: "Semiconductors" },
    { title: "Defence Technologies" },
    { title: "Software Technologies" },
    { title: "Data Science and Machine Learning" },
    { title: "Earthquake Resistent Technologies" },
    { title: "Vaccine Developments" },
    { title: "Commercial Aircraft Design and Manufacturing" },
    { title: "Clean Energy" },
    { title: "Bullet Train Technology" },
    { title: "Technology" },
    { title: "Research and Innovations" },
    { title: "Disease Control Methods" },
    { title: "Raw Materials" },
    { title: "Enterprise Software" },
    { title: "Design and Development of New Technolgies" },
    { title: "New Product Developments" },
    { title: "Flood Control" },
    { title: "Missile Technology" },
    { title: "Electronic and Communication Systems" },
    { title: "Micro Electronic Devices" },
    { title: "Satellite Systems" },
    { title: "Drone Technology" },
    { title: "Cutting- Edge Technologies" },
    { title: "Anti Missle Systems" },
    { title: "Programming" },
    { title: "Radar Technology" },
    { title: "Navigation Systems" },
    { title: "Operating Systems" },
    { title: "Cloud Computing" },
    { title: "Heavy Equipment" },
    { title: "Microbiology" },
    { title: "Hydraluics" },
    { title: "Remote Sensing Technologies" },
    { title: "Helicopter Design and Manufacturing" },
    { title: "Telecom Equipments" },
    { title: "Nural Technology" },
    { title: "Globalization" },
    { title: "Climate change" },
    { title: "Advanced Material Sciences" },
    { title: "Assistive Technologies" },
    { title: "Authentication Technology" },
    { title: "Battery Recycling" },
    { title: "Bio-Chips" },
    { title: "Computer Vision" },
    { title: "Condensed Matter Physics Theory" },
    { title: "Criminal Science & Forensics" },
    { title: "Cryptocurrencies" },
    { title: "Cryptography" },
    { title: "Cyber & Network Security" },
    { title: "Cyber Physical Systems" },
    { title: "Dark Web & Deep Web" },
    { title: "Data Encryption" },
    { title: "Digital Communications" },
    { title: "Electric Vehicles" },
    { title: "Emergency Medicine" },
    { title: "Facial Recognition" },
    { title: "Flexible Mobile Devices" },
    { title: "Food Science & Technologies" },
    { title: "Genetically Engineered & 3D Printed Organs" },
    { title: "Genetics & Genomics" },
    { title: "GIS & Geospatial Technology" },
    { title: "Grid Computing" },
    { title: "Health Information Systems" },
    { title: "Healthcare Administration" },
    { title: "Healthcare Policy & Reform" },
    { title: "High Performance Computing" },
    { title: "Human Computer Interface" },
    { title: "Hydrogen Planes" },
    { title: "Hydrology" },
    { title: "automa" },
    { title: "Internet, Data, & Social Media Privacy" },
    { title: "IT Policy & Standardization" },
    { title: "Medical Diagnosis & Treatment" },
    { title: "Medical Education" },
    { title: "Medical Technologies & Engineering" },
    { title: "Mobile & Wireless Computing" },
    { title: "Mobile Network Security" },
    { title: "Natural Language Processing" },
    { title: "Network Architecture" },
    { title: "Networking & Telecommunications" },
    { title: "Neuroscience" },
    { title: "Nuclear Science" },
    { title: "Pharmaceutical Sciences" },
    { title: "Preventative Health Care" },
    { title: "Psychiatry & Mental Health" },
    { title: "Public Health" },
    { title: "Quantum Computing" },
    { title: "Sensor Technology" },
    { title: "Smart Cities" },
    { title: "Software-Defined Networks" },
    { title: "Super Apps" },
    { title: "Surveillance Systems" },
    { title: "Sustainability & Sustainable Development" },
    { title: "Systems & Software Engineering" },
    { title: "Unmanned Aerial Vehicles (UAVs)" },
    { title: "Veterinary Science" },
    { title: "Water Resource Management" },
    { title: "Wearable Technology" },
    { title: "Web Technologies" },
    { title: "Wireless Sensor Networks" },
    { title: "Accounting & Finance" },
    { title: "Aging Workforce" },
    { title: "Alternative Funding Models" },
    { title: "Automated Business" },
    { title: "Business & Organizational Research" },
    { title: "Business Communications" },
    { title: "Business Education" },
    { title: "Business Intelligence" },
    { title: "Business Policy & Law" },
    { title: "Business-Society Management" },
    { title: "Consumer Health" },
    { title: "Data Privacy Legislation" },
    { title: "Demographic Pressure on Economy" },
    { title: "Digitization of Services" },
    { title: "E-Commerce" },
    { title: "Economic Interconnectedness" },
    { title: "Employee Mental Health & Wellbeing" },
    { title: "Employee Recruitment" },
    { title: "Employer Branding" },
    { title: "Experience Tourism" },
    { title: "Financial Technologies (Fintech)" },
    { title: "Gastronomy" },
    { title: "Gender Economics & Consumption" },
    { title: "Gig Economy" },
    { title: "Global Business" },
    { title: "Global Labor Standards" },
    { title: "Government Intervention in Business" },
    { title: "Hospitality, Travel, & Tourism Management" },
    { title: "Human Resource Management" },
    { title: "Hybrid Workplaces" },
    { title: "Influencer Marketing" },
    { title: "International Importing/Exporting Regulations" },
    { title: "International Trade Policies" },
    { title: "Management Science & Leadership" },
    { title: "Marketing" },
    { title: "Marketing Technology (MarTech)" },
    { title: "Mass Customization" },
    { title: "Micro-Enterprises" },
    { title: "Mobile Payment Systems" },
    { title: "Monetary Policy" },
    { title: "Organizational Behavior" },
    { title: "Product Development" },
    { title: "Professionalism" },
    { title: "Project Management" },
    { title: "Psychological Contract" },
    { title: "Reskilling the Workforce" },
    { title: "Risk Assessment" },
    { title: "Small & Medium Enterprises" },
    { title: "Social Brand Management" },
    { title: "Socially Conscious Consumers" },
    { title: "Startup Business" },
    { title: "Strategy & Innovation" },
    { title: "Supply Chain Management" },
    { title: "Technology & Operations Management" },
    { title: "Training & Retention" },
    { title: "Virtual Currencies" },
    { title: "Chatbots in Education" },
    { title: "Digital Libraries" },
    { title: "Higher Education" },
    { title: "Holistic Education" },
    { title: "Knowledge Discovery" },
    { title: "Knowledge Management" },
    { title: "Language Immersion Programs" },
    { title: "Library Science" },
    { title: "Mobile Devices in Education" },
    { title: "Next-Generation Apprenticeships" },
    { title: "Research Ethics" },
    { title: "Research Methods" },
    { title: "Computational Biology" },
    { title: "Multimedia Systems" },
    { title: "Internet of Things" },
    { title: "Telecom Software" },
    { title: "Wireless Technologies" },
    { title: "Acoustics" },
    { title: "Aerodynamics" },
    { title: "Analytical Instrumentation" },
    { title: "Atmospheric Sciences" },
    { title: "CAD/CAM" },
    { title: "Combustion and Propulsion" },
    { title: "Compliers" },
    { title: "Composite Structures" },
    { title: "Computational Science" },
    { title: "Computer Architecture" },
    { title: "Data Mining" },
    { title: "Electrochemistry" },
    { title: "Fluid Dynamics" },
    { title: "Geotechnical Engineering" },
    { title: "I.C.Engines" },
    { title: "Laser Spectoscopy" },
    { title: "Mechatronics" },
    { title: "Mechanical Systems" },
    { title: "Molecular Cell Biology" },
    { title: "Molecular Structure" },
    { title: "Photonics" },
    { title: "Plant Molecular Biology" },
    { title: "Polymer Chemestry" },
    { title: "Power Electronics" },
    { title: "Reproductive Biology" },
    { title: "Signal Processing" },
    { title: "Simulation" },
    { title: "Solid State Chemistry" },
    { title: "Structural Engineering" },
    { title: "Synthetic Organic Chemistry" },
    { title: "Organic Chemistry" },
    { title: "Inorganic Chemistry" },
    { title: "Thermal Sciences" },
    { title: "Transmission Systems" },
    { title: "Vaccum and Thin Film" },
    { title: "Information Security" },
    { title: "Intelligent Automation" },
    { title: "Cloud Computing" },
    { title: "Clinical Science" },
    { title: "History" },
    { title: "Journalism" },
    { title: "Political Science" },
  
  ]


  export const Maincategory = [
    {
      value: "Institution",
      label: "Institution",
    },
    {
      value: "Organisation",
      label: "Organisation",
    },
    {
      value: "Industry",
      label: "Industry",
    },
    {
      value: "Investment",
      label: "Investment",
    },
  ];

  export const  Subcategory = [
    {
      value: "National Level Institution",
      label: "National Level Institution",
    },
    {
      value: "National Level Management Institution",
      label: "National Level Management Institution",
    },
    {
      value: "National Level Technical Institution",
      label: "National Level Technical Institution",
    },
    {
      value: "State Government Institution",
      label: "State Government Institution",
    },
    {
      value: "State Level Technical Institution",
      label: "State Level Technical Institution",
    },
    {
      value: "State Level Management Institution",
      label: "State Level Management Institution",
    },
    /* {
      value: "State Government Technical Institution",
      label: "State Government Technical Institution",
    },
    {
      value: "State Private Technical Institution",
      label: "State Private Technical Institution",
    }, */
  {
    value: "Central University",
    label: "Central University",
  },
  {
    value: "State University",
    label: "State University",
  },
  {
    value: "Deemed University",
    label: "Deemed University",
  },
  {
    value: "State Private University",
    label: "State Private University",
  },
  {
    value: "Agricultural University",
    label: "Agricultural University",
  },
];

export const Subcategory2 = [
  {
    value: "National Level Research Organisation",
    label: "National Level Research Organisation",
  },
  {
    value: "State Level Research Organisation",
    label: "State Level Research Organisation",
  },
  {
    value:
      "Scientific and Industrial Research Organisation Registered with DSIR",
    label:
      "Scientific and Industrial Research Organisation Registered with DSIR",
  },
  {
    value: "Private R&D Registered with DSIR",
    label: "Private R&D Registered with DSIR",
  },
  {
    value: "Central Government Department",
    label: "Central Government Department",
  },
  {
    value: "State Government Department",
    label: "State Government Department",
  },
  {
    value: "Union Territory Government Department",
    label: "Union Territory Government Department",
  },
  {
    value: "Non-profit Organization",
    label: "Non-profit Organization",
  },
  {
    value:
      "Union Government Autonomous Bodies/ Boards/ Societies/ Agencies/ Corporations",
    label:
      "Union Government Autonomous Bodies/ Boards/ Societies/ Agencies/ Corporations",
  },
  {
    value:
      "State Government Autonomous Bodies/ Boards/ Societies/ Agencies/ Corporations",
    label:
      "State Government Autonomous Bodies/ Boards/ Societies/ Agencies/ Corporations",
  },
  /* {
      value: "Government Department",
      label: "Government Department",
    }, */
];

export const Subcategory3 = [
  {
    value: "National Level Public Sector Industry",
    label: "National Level Public Sector Industry",
  },
  {
    value: "State Level Public Sector Industry",
    label: "State Level Public Sector Industry",
  },
  {
    value: "Private Sector Industry",
    label: "Private Sector Industry",
  },
  /* {
      value: "BSE 100 (Top 100 Companies by Market Capitalization)",
      label: "BSE 100 (Top 100 Companies by Market Capitalization)",
    },
    {
      value: "NSE 100(Top 100 Companies by Market Capitalization)",
      label: "NSE 100(Top 100 Companies by Market Capitalization)",
    }, */
    {
      value: "BSE Listed Company",
      label: "BSE Listed Company",
    },
    {
      value: "NSE Listed Company",
      label: "NSE Listed Company",
    },
    {
      value: "Startup",
      label: "Startup",
    },
    {
      value: "Unicorn Company",
      label: "Unicorn Company",
    },
  
];

  export const Subcategory4 = [
    {
      value: "Investment company",
      label: "Investment company",
    },
    {
      value: "Government Startup Program",
      label: "Government Startup Program",
    },
    {
      value: "Private Startup Program",
      label: "Private Startup Program",
    },
  ];

  export const StudentCategory = [
    {
      value: "Student",
      label: "Student ",
    },
    {
      value: "Faculty Member",
      label: "Faculty Member",
    },
    {
      value: "Scientist",
      label: "Scientist",
    },
    {
      value: "Employee",
      label: "Employee",
    },
    {
      value: "Researcher",
      label: "Researcher",
    },
    {
      value: "Entrepreneur",
      label: "Entrepreneur",
    },
  ];


  
  
