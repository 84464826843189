import { Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Home from "./pages/home/index";
import SignIOI from "./pages/signauth/signIOI";
import Login from "./pages/loginauth/login";
import Ioiprofile from "./pages/ioiprofile";
import Navbar from "./components/index";
import ProfileNavbar from "./components/profilenavbar";
import Connection from "./pages/connect/connection";
import Jobs from "./pages/jobs/index";
import Editjob from "./pages/jobs/Editjob";
import Addjobs from "./pages/jobs/Addjobs";
import Addinternships from "./pages/jobs/Addinternships";
import Addfellowships from "./pages/jobs/Addfellowships";
import Applicants from "./pages/jobs/Applicants";
import PrivateRoutes from "./shared/privateRoute";
import IoiProfilepage from "./pages/connect/IoiProfilepage";
import VerifyPhone from "./pages/signauth/verifyPhoneNew";
import IndividualProfile from "./pages/connect/IndividualProfile";
import ResetPassword from "./pages/loginauth/resetPassword";
import ForgotPassword from "./pages/loginauth/forgotPassword";
import NotFound from "./shared/notFound";
import Privacypolicy from "./shared/privacypolicy";
import Agreement from "./shared/userAgreement";
import Profileview from "./pages/jobs/profileview";
import Shortlist from "./pages/jobs/shortlist";
import DomesticSolutions from "./pages/broadcast/domesticSolutions";
import AI from "./pages/broadcast/AI";
import DreamTrue from "./pages/broadcast/dreamTrue";
import Bharc from "./pages/broadcast/bharc";
// import Topics from "./pages/topics/index";
// import Addtopics from "./pages/topics/Addtopics";
// import Topicstab from "./pages/topics/Topicstab";
import Publish from "./pages/publish/index";
// import Addpublish from "./pages/publish/Addpublish";
import Generaldocuments from "./pages/publish/Generaldocuments";
// import Patentcertificates from "./pages/publish/Patentcertificates";
import Researchpapers from "./pages/publish/Researchpapers";
import Explore from "./pages/explore/index";
// import Addexplore from "./pages/explore/Addexplore";
import Ads from "./pages/explore/Ads";
import Competitions from "./pages/explore/Competitions";
import Conferences from "./pages/explore/Conferences";
import Otherevents from "./pages/explore/Otherevents";
import Myevents from "./pages/explore/Myevents";
import CreateEvent from "./pages/explore/CreateEvent";
import ViewCertificate from "./pages/publish/ViewCertificate";
import PublishCertificate from "./pages/publish/PublishCertificate";
import MyPublish from "./pages/publish/MyPublish";
import CreateAdd from "./pages/explore/createAdd";
import ViewAds from "./pages/explore/viewAdds";
import SavedAds from "./pages/explore/saveAdd";
import SavedPublish from "./pages/publish/saved_publish";

import ViewConferences from "./pages/explore/ViewConferences";
import ViewCompetitions from "./pages/explore/ViewCompetitions";
import ViewOtherevents from "./pages/explore/ViewOtherevents";
import MyAds from "./pages/explore/MyAds";

// import ViewMyCertificate from "./pages/publish/ViewMyCertificate";

function App() {
  const [route, setRoute] = useState(true);

  return (
    <div className="App">
      <Routes>
        <Route path="/" Component={Login} />
        <Route path="/sign-up" Component={SignIOI} />
        <Route path="/privacy" element={<Privacypolicy />} />
        <Route path="/agreement" element={<Agreement />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/view_adds/:id"
          element={
            <Navbar>
              <ViewAds />
            </Navbar>
          }
        />

        <Route element={<PrivateRoutes />}>
          <Route path="/home" Component={Home} />
          <Route
            path="/verify-phone"
            element={
              <Navbar>
                <VerifyPhone />
              </Navbar>
            }
          />
          <Route path="/ioiprofile" element={<Ioiprofile />} />
          <Route
            path="/connection"
            element={
              <Navbar>
                <Connection />{" "}
              </Navbar>
            }
          />
          <Route
            path="/jobs"
            element={
              <Navbar>
                <Jobs />
              </Navbar>
            }
          />
          <Route
            path="/addJobs"
            element={
              <Navbar>
                <Addjobs />
              </Navbar>
            }
          />
          <Route
            path="/addInternships"
            element={
              <Navbar>
                <Addinternships />
              </Navbar>
            }
          />
          <Route
            path="/addFellowships"
            element={
              <Navbar>
                <Addfellowships />
              </Navbar>
            }
          />
          <Route
            path="/editjob/:id"
            element={
              <Navbar>
                <Editjob />
              </Navbar>
            }
          />
          <Route
            path="/applicants/:id"
            element={
              <Navbar>
                <Applicants />
              </Navbar>
            }
          />
          <Route
            path="/shortlist/:id"
            element={
              <Navbar>
                <Shortlist />
              </Navbar>
            }
          />
          <Route
            path="/view-ioiprofile/:id"
            element={
              <Navbar>
                <IoiProfilepage />
              </Navbar>
            }
          />
          <Route
            path="/profile/:id"
            element={
              <Navbar>
                <IndividualProfile />
              </Navbar>
            }
          />
          <Route path="*" element={<NotFound />} />
          <Route
            path="/applicants/:id/:applicantId"
            element={
              <Navbar>
                <Profileview />
              </Navbar>
            }
          />
          <Route
            path="/domesticsolutions"
            element={
              <Navbar>
                <DomesticSolutions />
              </Navbar>
            }
          />
          <Route
            path="/AI"
            element={
              <Navbar>
                <AI />
              </Navbar>
            }
          />
          <Route
            path="/dreamtrue"
            element={
              <Navbar>
                <DreamTrue />
              </Navbar>
            }
          />
          <Route
            path="/bharc"
            element={
              <Navbar>
                <Bharc />
              </Navbar>
            }
          />
          {/* <Route
            path="/topics"
            element={
              <Navbar>
                <Topics />
              </Navbar>
            }
          /> */}
          {/* <Route
            path="/Addtopics"
            element={
              <Navbar>
                <Addtopics />
              </Navbar>
            }
          /> */}
          {/* <Route
            path="/Topicstab"
            element={
              <Navbar>
                <Topicstab />
              </Navbar>
            }
          /> */}
          <Route
            path="/publish"
            element={
              <Navbar>
                <Publish />
              </Navbar>
            }
          />
          {/* <Route path="/Addpublish" element={<Navbar><Addpublish/></Navbar>} /> */}
          <Route
            path="/Generaldocuments"
            element={
              <Navbar>
                <Generaldocuments />
              </Navbar>
            }
          />
          {/* <Route path="/Patentcertificates" element={<Navbar><Patentcertificates/></Navbar>} /> */}
          <Route
            path="/Researchpapers"
            element={
              <Navbar>
                <Researchpapers />
              </Navbar>
            }
          />
          <Route
            path="/explore"
            element={
              <Navbar>
                <Explore />
              </Navbar>
            }
          />
          {/* <Route
            path="/Addexplore"
            element={
              <Navbar>
                <Addexplore />
              </Navbar>
            }
          /> */}
          <Route
            path="/Ads"
            element={
              <Navbar>
                <Ads />
              </Navbar>
            }
          />
          <Route
            path="/Otherevents"
            element={
              <Navbar>
                <Otherevents />
              </Navbar>
            }
          />
          <Route
            path="/Conferences"
            element={
              <Navbar>
                <Conferences />
              </Navbar>
            }
          />
          <Route
            path="/Competitions"
            element={
              <Navbar>
                <Competitions />
              </Navbar>
            }
          />
          <Route
            path="/Myevents"
            element={
              <Navbar>
                <Myevents />
              </Navbar>
            }
          />
          <Route
            path="/CreateEvent"
            element={
              <Navbar>
                <CreateEvent />
              </Navbar>
            }
          />
          <Route
            path="/ViewCertificate/:id"
            element={
              <Navbar>
                <ViewCertificate />
              </Navbar>
            }
          />
          <Route
            path="/PublishCertificate"
            element={
              <Navbar>
                <PublishCertificate />
              </Navbar>
            }
          />
          <Route
            path="/MyPublish"
            element={
              <Navbar>
                <MyPublish />
              </Navbar>
            }
          />
          <Route
            path="/CreateAdd"
            element={
              <Navbar>
                <CreateAdd />
              </Navbar>
            }
          />
          <Route
            path="/ViewAds/:id"
            element={
              <Navbar>
                <ViewAds route={route} />
              </Navbar>
            }
          />
          <Route
            path="/SavedAds"
            element={
              <Navbar>
                <SavedAds />
              </Navbar>
            }
          />
          <Route
            path="/save_publish"
            element={
              <Navbar>
                <SavedPublish />
              </Navbar>
            }
          />

           <Route
            path="/ViewConferences"
            element={
              <Navbar>
                <ViewConferences />
              </Navbar>
            }
           />

           <Route
            path="/ViewCompetitions"
            element={
              <Navbar>
                <ViewCompetitions />
              </Navbar>
            }
           />

           <Route
            path="/ViewOtherevents"
            element={
              <Navbar>
                <ViewOtherevents />
              </Navbar>
            }
           />

          <Route
            path="/MyAds"
            element={
              <Navbar>
                <MyAds />
              </Navbar>
            }
           />


          {/* <Route path="/ViewMyCertificate" element={<Navbar><ViewMyCertificate/></Navbar>} /> */}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
