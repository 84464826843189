import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Tooltip,
  FormControl,
  TextField,
  IconButton,
} from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch, useSelector } from "react-redux";
import { deleteJob, fetchPostedJobs, jobsSelector } from "../../api/jobs";
import { fetchIOIProfile, ioiuserSelector } from "../../api/ioiUser";
import WorkIcon from "@mui/icons-material/Work";
import AddIcon from "@material-ui/icons/Add";
import FilterIcon from "@mui/icons-material/FilterList";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ShareIcon from "@mui/icons-material/Share";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import image2 from "../../images/logo.png";
import image3 from "../../images/bharcimg.png";
import "./Explore.css";
import { RWebShare } from "react-web-share";
import imagePlaceholder from "../../images/logo.png";
import { GiTeamIdea } from "react-icons/gi";
import { PiExamBold } from "react-icons/pi";
import { MdOutlineEmojiEvents } from "react-icons/md";
import { LiaBuysellads } from "react-icons/lia";
import { MdEventAvailable } from "react-icons/md";
import { FaDownload } from "react-icons/fa6";
import { FaAd } from "react-icons/fa";

import ExploreIcon from "@mui/icons-material/Explore";

function SavedAds() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { jobs } = useSelector(jobsSelector);
  const { user, token } = useSelector(ioiuserSelector);

  // State for product and service ads
  const [productAds, setProductAds] = useState([]);
  const [serviceAds, setServiceAds] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [open, setOpen] = useState(false);
  const [deletionId, setDeletionId] = useState("");
  const [showFullDescription, setShowFullDescription] = useState(false);
  const toggleDescription = () => setShowFullDescription(!showFullDescription);

  const nav = useNavigate();

  // Fetch saved ads and categorize into Product and Service
  useEffect(() => {
    if (user?.adds_saved) {
      const products = user.adds_saved.filter(
        (ad) => ad.add_type === "product"
      );
      console.log(products, "profucts");

      const services = user.adds_saved.filter(
        (ad) => ad.add_type === "service"
      );
      console.log(services, "service");
      setProductAds(products);
      setServiceAds(services);
    }
  }, [user?.adds_saved]);

  useEffect(() => {
    dispatch(fetchIOIProfile(token));
  }, [token, dispatch]);

  useEffect(() => {
    dispatch(fetchPostedJobs(user?._id));
  }, [user, dispatch]);

  const handleClick = () => {
    setIsSaved((prevState) => !prevState); // Toggle between saved/not saved
  };

  const handleOpen = (id) => {
    setDeletionId(id);
    setOpen(true);
  };

  const renderRecentAdMedia = (ad) => {
    if (!ad.document || ad.document.length === 0) {
      return <span>No media available</span>;
    }

    const mostRecentDocument = ad.document[ad.document.length - 1];
    const isVideo = mostRecentDocument.includes(".mp4");
    const isImage = [".jpg", ".jpeg", ".png", ".webp"].some((ext) =>
      mostRecentDocument.includes(ext)
    );

    return isVideo ? (
      <LazyVideo src={mostRecentDocument} />
    ) : isImage ? (
      <img
        src={mostRecentDocument}
        alt="Ad Media"
        loading="lazy" // Lazy loading for image
        className="w-full h-40 object-cover rounded-lg"
      />
    ) : (
      <span>Unsupported format</span>
    );
  };

  // Lazy loading component for video
  const LazyVideo = ({ src }) => {
    const videoRef = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsLoaded(true); // Start loading video when in view
            observer.unobserve(entry.target); // Stop observing after load
          }
        },
        { threshold: 0.5 }
      );

      if (videoRef.current) {
        observer.observe(videoRef.current);
      }

      return () => {
        if (videoRef.current) {
          observer.unobserve(videoRef.current);
        }
      };
    }, []);

    return (
      <video
        ref={videoRef}
        src={isLoaded ? src : ""}
        preload="metadata"
        controls={isLoaded} // Show controls only when loaded
        className="w-full h-full object-contain rounded-lg"
      />
    );
  };
  // Lazy loading component for video

  const handleClose = () => setOpen(false);

  const handelDelete = () => {
    dispatch(deleteJob(deletionId, user?._id));
    setOpen(false);
  };

  const reloadButton = () => {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };

  return (
    <div>
      <section className="py-8" style={{ fontFamily: "Lato" }}>
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-3">
            {/* Left Sidebar */}
            <div class="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <div class="w-full mb-6">
                <div class="relative p-6 text-center bg-gray-100 rounded-xl">
                  <img
                    class="block mx-auto mb-5 w-28 h-28 rounded-full"
                    src={
                      user?.profile_completion?.logo
                        ? user?.profile_completion?.logo
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                    alt=""
                  />
                  <h4 class="text-lg text-[#0F3D79] font-bold mb-3">
                    {user?.name}
                  </h4>
                  <div class="flex items-center justify-center mb-6">
                    <p class="text-md font-semibold text-gray-500">
                      {user?.sub_category}
                    </p>
                  </div>
                </div>
              </div>
              <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/Conferences">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <GiTeamIdea />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Conferences
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Competitions">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <PiExamBold />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Competitions
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Otherevents">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <MdOutlineEmojiEvents />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Other Events
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Ads">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <LiaBuysellads />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Myevents">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <MdEventAvailable />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        My Events
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/SavedAds">
                  <div
                    class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                    style={{
                      backgroundColor: pathname.includes("SavedAds")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  >
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <FaDownload />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Saved Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/MyAds">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <FaAd />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        My Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            {/* Main Content */}
            <div className="w-full lg:w-[78%] px-1">
              <div className="h-full py-1 px-1 sm:px-1 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  {/* Header */}
                  <div className="m-4">
                    <h2 className="text-lg font-semibold text-[#0F3D79]">
                      Saved Ads
                    </h2>
                  </div>

                  {/* Search and Filters */}
                  <div className="m-4 flex flex-col lg:flex-row justify-between items-center">
                    <div className="flex-grow w-full lg:w-auto mb-4 lg:mb-0">
                      <div className="relative">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <SearchIcon className="text-gray-500" />
                        </span>
                        <input
                          type="text"
                          placeholder="Search Organisation name"
                          className="py-2 pl-10 pr-4 w-full lg:w-1/2 rounded-xl border border-gray-300"
                        />
                      </div>
                    </div>
                    <div className="flex items-center w-full lg:w-auto">
                      <button className="py-2 px-4 text-[#0F3D79] font-semibold border rounded-xl bg-white">
                        <FilterIcon /> Filter
                      </button>
                      <button className="py-2 px-4 text-[#0F3D79] font-semibold border rounded-xl bg-white ml-4">
                        <CalendarTodayIcon /> Date
                      </button>
                      <Link to="/CreateAdd" className="ml-4">
                        <button
                          className="py-2 px-2 text-white font-semibold border rounded-xl bg-[#002357]"
                          onClick={reloadButton}
                        >
                          <AddIcon /> Create an Ad
                        </button>
                      </Link>
                    </div>
                  </div>

                  {/* Product Ads */}
                  <h2 className="text-sm font-semibold text-[#0F3D79] mb-5 ml-3">
                    Product Ads
                  </h2>
                  <div className="m-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {productAds.length > 0 ? (
                      productAds.map((ad, index) => (
                        <div
                          key={ad._id}
                          className="p-6 bg-white rounded-xl shadow-md min-h-[30rem] flex flex-col justify-between"
                        >
                          <NavLink to={`/ViewAds/${ad._id}`}>
                            <div className="flex items-center mb-4">
                              <img
                                src={
                                  ad?.add_posted_by_ioi?.profile_completion
                                    ?.logo ||
                                  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                }
                                alt="Profile"
                                className="w-10 h-10 rounded-full mr-4 object-cover"
                              />
                              <div className="min-h-[2rem]">
                                <h3 className="text-[#0F3D79] text-md font-semibold">
                                  {ad.add_posted_by_ioi?.name || "Anonymous"}
                                </h3>
                                <p className="text-sm text-gray-500">
                                  {ad.add_posted_by_ioi?.sub_category ||
                                    "Unknown Category"}
                                </p>
                              </div>
                            </div>
                            <h4 className="text-[#0F3D79] font-semibold mb-2">
                              {ad.add_title}
                            </h4>
                            <p
                              className={`text-sm text-gray-600 mb-4 min-h-[3rem] ${
                                showFullDescription
                                  ? ""
                                  : "line-clamp-2 overflow-hidden"
                              } tracking-wide`}
                              style={{ lineHeight: "1.5rem" }}
                            >
                              {ad.description}
                              {ad.description.length > 100 &&
                                !showFullDescription && (
                                  <span
                                    onClick={toggleDescription}
                                    className="text-blue-500 cursor-pointer ml-1"
                                  >
                                    Read More
                                  </span>
                                )}
                            </p>
                            <div className="flex justify-center items-center h-[20rem] rounded-lg overflow-hidden">
                              {renderRecentAdMedia(ad)}
                            </div>
                          </NavLink>
                          <div className="flex items-center mt-4 gap-x-4">
                            <RWebShare
                              data={{
                                text: "Check out this Ad",
                                url: `http://user.bharc.in/view_adds/${ad._id}`,
                                title: "Ad Share",
                              }}
                            >
                              <button className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold">
                                Share
                                <ShareIcon className="ml-2 text-[#346BB7]" />
                              </button>
                            </RWebShare>
                            {/* {user?.adds_saved?.some(
                              (saved) => saved._id === ad._id
                            ) ? (
                              <button
                                className="flex items-center justify-between px-4 py-2 bg-[#002357] border border-gray-300 rounded-lg text-sm text-white hover:bg-[#001a44] font-semibold"
                                onClick={() => handleSaveAdd(ad._id)}
                              >
                                Saved
                                <BookmarkBorderOutlinedIcon
                                  className="ml-2"
                                  style={{ fill: "white" }}
                                />
                              </button>
                            ) : (
                              <button
                                className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold"
                                onClick={() => handleSaveAdd(ad._id)}
                              >
                                Save
                                <BookmarkBorderOutlinedIcon
                                  className="ml-2"
                                  style={{ fill: "#346BB7" }}
                                />
                              </button>
                            )} */}
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No product ads saved.</p>
                    )}
                  </div>

                  {/* Service Ads */}
                  <h2 className="text-sm font-semibold text-[#0F3D79] mb-5 ml-3 mt-5">
                    Service Ads
                  </h2>
                  <div className="m-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {serviceAds.map((ad) => (
                      <div
                        key={ad._id}
                        className="bg-white p-6 rounded-xl shadow-md"
                      >
                        <NavLink to={`/ViewAds/${ad._id}`}>
                          <div className="flex items-center mb-4">
                            <img
                              src={
                                ad?.add_posted_by_ioi?.profile_completion
                                  ?.logo ||
                                "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                              }
                              alt="Profile"
                              className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-fit"
                            />
                            <div>
                              <h3 className="text-[#0F3D79] text-md font-semibold">
                                {ad.add_posted_by_ioi?.name || "Anonymous"}
                              </h3>
                              <p className="text-sm text-gray-500">
                                {ad.add_posted_by_ioi?.sub_category ||
                                  "Unknown Category"}
                              </p>
                            </div>
                          </div>
                          <h4 className="text-[#0F3D79] font-semibold mb-2">
                            {ad.add_title}
                          </h4>
                          <p className="text-sm text-gray-600 mb-4">
                            {ad.description}
                          </p>
                          <div className="flex justify-center items-center h-40 bg-gray-100 rounded-lg overflow-hidden mb-4">
                            {renderRecentAdMedia(ad)}
                          </div>
                        </NavLink>
                        <div className="flex items-center mt-4 gap-x-4">
                          <RWebShare
                            data={{
                              text: "Check out this Ad",
                              url: `http://localhost:3000/view_adds/${ad._id}`,
                              title: "Ad Share",
                            }}
                          >
                            <button className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold">
                              Share
                              <ShareIcon className="ml-2 text-[#346BB7]" />
                            </button>
                          </RWebShare>
                          {/* {user?.adds_saved?.some(
                            (saved) => saved._id === ad._id
                          ) ? (
                            <button
                              className="flex items-center justify-between px-4 py-2 bg-[#002357] border border-gray-300 rounded-lg text-sm text-white hover:bg-[#001a44] font-semibold"
                              onClick={() => handleSaveAdd(ad._id)}
                            >
                              Saved
                              <BookmarkBorderOutlinedIcon
                                className="ml-2"
                                style={{ fill: "white" }}
                              />
                            </button>
                          ) : (
                            <button
                              className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold"
                              onClick={() => handleSaveAdd(ad._id)}
                            >
                              Save
                              <BookmarkBorderOutlinedIcon
                                className="ml-2"
                                style={{ fill: "#346BB7" }}
                              />
                            </button>
                          )} */}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SavedAds;
