import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import ProfileSection from "./profileSection";
import RecommendSection from "./recommendSection";
import { useDispatch, useSelector } from "react-redux";
import {
  ioiuserSelector,
  fetchIOIProfile,
  fetchRecommendation,
} from "../../api/ioiUser";
import Projects from "./projects";

export default function Index() {
  const dispatch = useDispatch();
  const { token, user, recommend } = useSelector(ioiuserSelector);
  const userId = user?._id;

  const [profile, setProfile] = useState(false);

  ////console.log(recommend, "recoemnd");

  // <Navbar profile={profile}/>

  React.useEffect(() => {
    dispatch(fetchIOIProfile(token));
  }, [token]);

  React.useEffect(() => {
    dispatch(fetchRecommendation(user?._id));
  }, [userId]);

  return (
    <div>
      <Navbar profile={profile} />
      <ProfileSection />
      <Projects />
      {/* <Section1/> */}
      {/* <RecommendSection recommend_sug={recommend} user={user} token={token} /> */}
    </div>
  );
}
