import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import WorkIcon from "@mui/icons-material/Work";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deleteJob, fetchPostedJobs, jobsSelector } from "../../api/jobs";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Modal from "@mui/material/Modal";
import { fetchIOIProfile, ioiuserSelector } from "../../api/ioiUser";
import AddIcon from "@material-ui/icons/Add";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { FormControl } from "@mui/material";
import { Autocomplete } from "@mui/joy";
import { TextField, IconButton } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Logo from "../../images/comingsoon.png";
import FilterIcon from "@mui/icons-material/FilterList";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import image2 from "../../images/logo.png";
import image3 from "../../images/bharcimg.png";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; // Import a PDF icon
import {
  publishSelector,
  fetchOnePublish,
  deletePublish,
} from "../../api/publish";
import { useParams } from "react-router";

import ExploreIcon from "@mui/icons-material/Explore";

function ViewConferences() {
  const { token, user, connected, request_received } =
    useSelector(ioiuserSelector);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { id } = useParams();
  const { publish, currentPublish } = useSelector(publishSelector);

  const navigate = useNavigate();
  console.log(currentPublish);

  console.log(id, "id");

  useEffect(() => {
    dispatch(fetchOnePublish(id));
  }, [id]);

  const handleDeletePublish = (deleteId) => {
    dispatch(deletePublish(deleteId, token));
    navigate("/publish");
  };

  console.log(publish, "view");
  console.log(currentPublish, "current topic view");

  const reloadButton = () => {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };

  // Filtered topics based on search query

  return (
    <div>
      <section class="py-8" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-3">
            <div className="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <div className="w-full mb-6">
                <div className="relative p-6 text-center bg-gray-100 rounded-xl">
                  <img
                    className="block mx-auto mb-5 w-28 h-28 rounded-full"
                    src={
                      user?.profile_completion?.logo ||
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                    alt=""
                  />
                  <h4 className="text-lg text-[#0F3D79] font-bold mb-3">
                    {user?.name}
                  </h4>
                  <div className="flex items-center justify-center mb-6">
                    <p className="text-md font-semibold text-gray-500">
                      {user?.sub_category}
                    </p>
                  </div>
                </div>
              </div>

              <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/Conferences">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Conferences
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Competitions">
                  <div
                    class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                 
                  >
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Competitions
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Otherevents">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                     style={{
                      backgroundColor: pathname.includes("Otherevents")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  >
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Other Events
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Ads">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Myevents">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        My Events
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/SavedAds">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Saved Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/MyAds">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                       My Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="w-full lg:w-[78%] px-3">
  <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
    <div className="md:max-w-6xl mx-auto">
      {/* Header */}
      <div className="m-4 flex justify-between items-center">
        <h2 className="text-lg font-semibold text-[#0F3D79]">
         View Conference
        </h2>
     
      </div>

      {/* Main Content */}
      <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center">
        {/* User and Certificate Details */}
        <div className="w-full lg:w-1/2 bg-white mb-4 lg:mb-0 rounded-xl shadow-md h-[500px]">
          <div className="border p-6 rounded-md h-full overflow-y-auto">
            <div className="flex items-center mb-4">
              <img
                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                alt="Profile"
                className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-fit"
              />
              <div>
                <h3 className="text-[#0F3D79] text-md font-semibold">
                  Karthik Karthik
                </h3>
                <p className="text-sm text-gray-500">
                  Software Developer
                </p>
              </div>
            </div>

            <h4 className="mt-4 text-[#0F3D79] text-lg font-semibold">
              Topic Name Goes Here
            </h4>
            <p className="mt-2 text-gray-700 text-sm leading-relaxed">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sollicitudin et enim non ultricies. 
              Etiam fringilla pellentesque dictum. Morbi sollicitudin placerat vulputate. Donec tristique lectus a eros ultricies commodo.
              Sed pulvinar massa, in venenatis velit. Proin malesuada ac elit eu commodo. Pellentesque suscipit mattis accumsan.
            </p>
            <p className="mt-2 text-gray-700 text-sm leading-relaxed">
              Sed sit amet justo eget odio ultricies lobortis in quis eros. Sed eleifend sem leo, tempor consequat. Proin ac nisl dui tincidunt varius.
              Etiam loremet convallis ultricies. Nullam et quam turpis. Cras convallis pretium diam vitae semper.
            </p>
          </div>
        </div>

        {/* PDF Display */}
       <div className="w-full lg:w-1/2 flex justify-center ml-2">
          <iframe
            src="" // Replace this with any public PDF URL
            width="100%"
            height="500px"
            style={{ border: "none", backgroundColor: "white" }}
            title="PDF Viewer"
          />
        </div>

      </div>
    </div>
  </div>
</div>


          </div>
        </div>
      </section>
    </div>
  );
}

export default ViewConferences;
