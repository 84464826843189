import { configureStore } from "@reduxjs/toolkit";
import verificationemailReducer from "./verifyemail";
import ioiReducer from "./ioiUser";
import jobsReducer from "./jobs";
import individualReducer from "./individualUser";
import brodcastReducer from "./broadcast";
import publishReducer from "./publish";
import exploreReducer from "./expore";

export default configureStore({
  reducer: {
    verificationemail: verificationemailReducer,
    ioiuser: ioiReducer,
    jobs: jobsReducer,
    individualuser: individualReducer,
    broadcast: brodcastReducer,
    publish: publishReducer,
    explore: exploreReducer,
  },
});
