import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri, config } from "../key";
import { toast } from "react-toastify";

export const initialState = {
  loading: false,
  hasErrors: false,
  jobs: [],
  ioiJobs: [],
  currentjob: {},
};

export const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    getJobLoading: (state) => {
      state.loading = true;
    },
    getAllJobsSuccessfull: (state, { payload }) => {
      state.loading = false;
      state.jobs = payload?.jobApplicants;
    },
    getJobsOfIoi: (state, { payload }) => {
      state.loading = false;
      state.ioiJobs = payload;
    },
    getCrrentJobSuccessfull: (state, { payload }) => {
      state.loading = false;
      state.currentjob = payload?.job;
    },
    getCrrentJobSuccessfull: (state, { payload }) => {
      state.loading = false;
      state.currentjob = payload?.job;
    },
    getJobFalied: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const {
  getJobLoading,
  getAllJobsSuccessfull,
  getJobsOfIoi,
  getCrrentJobSuccessfull,
  getJobFalied,
} = jobsSlice.actions;

export const jobsSelector = (state) => state.jobs;
export default jobsSlice.reducer;

export const fetchAlljobs = () => async (dispatch) => {
  const key = "jobs";
  dispatch(getJobLoading());
  try {
    const { data } = await axios(keyUri.BACKEND_URI + "/ioiUsers/get-all-job");
    dispatch(getAllJobsSuccessfull(data));
    ////console.log(data);
    // toast.success(`${data.message}`, {
    //   position: "top-center",
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    // });
  } catch (error) {
    toast.error(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getJobFalied());
  }
};

export const createJobs = (value) => async (dispatch) => {
  const key = "job";
  dispatch(getJobLoading());
  try {
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/ioiUsers/post-job",
      value,
      config
    );
    dispatch(getAllJobsSuccessfull());
    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    toast.success(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getJobFalied());
  }
};

export const fetchOneJob = (id) => async (dispatch) => {
  const key = "job";
  dispatch(getJobLoading());
  try {
    const { data } = await axios(
      keyUri.BACKEND_URI + `/ioiUsers/get-a-job/${id}`
    );
    dispatch(getCrrentJobSuccessfull(data));
  } catch (error) {
    toast.error(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getJobFalied());
  }
};

export const updateJob = (id, value) => async (dispatch) => {
  const key = "job";
  dispatch(getJobLoading());
  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/ioiUsers/update-a-job/${id}`,
      value,
      config
    );
    dispatch(getAllJobsSuccessfull());
    toast.success(`${data?.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    toast.success(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getJobFalied());
  }
};

export const deleteJob = (id, userid) => async (dispatch) => {
  dispatch(getJobLoading());
  try {
    const { data } = await axios.delete(
      keyUri.BACKEND_URI + `/ioiUsers/delete-a-job/${id}`
    );
    dispatch(fetchPostedJobs(userid));
    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    toast.success(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getJobFalied());
  }
};

export const fetchPostedJobs = (id) => async (dispatch) => {
  dispatch(getJobLoading());
  try {
    if (id) {
      const { data } = await axios(
        keyUri.BACKEND_URI + `/ioiUsers/job-applicants/${id}`
      );
      dispatch(getAllJobsSuccessfull(data));
    }
  } catch (error) {
    toast.error(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getJobFalied());
  }
};

export const jobShortlist = (id, applicantId) => async (dispatch) => {
  dispatch(getJobLoading());
  ////console.log(id, applicantId);
  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/ioiUsers/shortlist-individual/${id}`,
      applicantId
    );
    dispatch(fetchOneJob(id));
    toast.success(`${data?.message}`, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    toast.error(`${error?.response?.data?.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getJobFalied());
  }
};
