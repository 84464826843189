const keyUri = {
  BACKEND_URI: "https://king-prawn-app-p955d.ondigitalocean.app/api",
  // BACKEND_URI: "http://localhost:5000/api",
};

let token = localStorage.getItem("tokenIOI");
const config = {
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  },
};

export { keyUri, config };
