import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri, config } from "../key";
import { toast } from "react-toastify";

export const initialState = {
  loading: false,
  hasErrors: false,
  verificationemail: [],
  currentverificationemail: null,
  validationdetails: {},
};

export const verificationemailSlice = createSlice({
  name: "verificationemail",
  initialState,
  reducers: {
    getverificationemail: (state) => {
      state.loading = true;
    },

    getverificationemailSuccess: (state, { payload }) => {
      state.loading = false;
      state.verificationemail = payload;
    },

    getCurrentverificationemail: (state, { payload }) => {
      state.loading = false;
      state.currentverificationemail = payload;
    },
    getverificationemailFailure: (state, { payload }) => {
      state.loading = false;
      state.verificationemail = payload;
    },
    getverificationDetail: (state, { payload }) => {
      state.loading = false;
      state.validationdetails = payload;
    },
  },
});

export const {
  getverificationemail,
  getverificationemailSuccess,
  getCurrentverificationemail,
  getverificationemailFailure,
  getverificationDetail,
} = verificationemailSlice.actions;

export const verificationemailSelector = (state) => state.verificationemail;
export default verificationemailSlice.reducer;

export const fetchAllverificationemail = () => async (dispatch) => {
  const key = "verificationemail";
  dispatch(getverificationemail());

  try {
    const { data } = await axios.get(
      keyUri.BACKEND_URI + "/users/verificationemail"
    );
    dispatch(getverificationemailSuccess(data));
  } catch ({ response }) {
    dispatch(getverificationemailFailure(response));
  }
};

export const createverificationemail = (values) => async (dispatch) => {
  const key = "verificationemail";
  dispatch(getverificationemail());


  try {
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/users/verificationemail",
      values,
      config
    );
    toast.success(`${data.msg}`, {
      position: "top-center",
      autoClose: 1800,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setTimeout(() => {
      dispatch(getverificationDetail(data.verificationemail));
    }, 2500);
    dispatch(fetchAllverificationemail());
  } catch ({ response }) {
    setTimeout(() => {
      toast.error(`${response.response.data.message}`, {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }, 200);
    dispatch(getverificationemailFailure(response));
  }
};

export const deleteverificationemail = (id) => async (dispatch) => {
  const key = "verificationemail";
  dispatch(getverificationemail());

  try {
    const { data } = await axios.delete(
      keyUri.BACKEND_URI + `/users/verificationemail/${id}`
    );
    dispatch(fetchAllverificationemail());
  } catch ({ response }) {
    dispatch(getverificationemailFailure(response));
  }
};

export const updateverificationemail = (id, values) => async (dispatch) => {
  const key = "verificationemail";
  dispatch(getverificationemail());

  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/users/verificationemail/${id}`,
      values,
      config
    );
    dispatch(getverificationDetail(data.verificationemail));
    toast.success(`${data.msg}`, {
      position: "top-center",
      autoClose: 1800,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    //   window.location.reload()
    //   dispatch(fetchAllverificationemail())
  } catch ({ response }) {
    dispatch(getverificationemailFailure(response));
      setTimeout(() => {
      toast.error(`${response.response.data.message}`, {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }, 200);
  }
};
