import React, { useState } from "react";
import Tab1 from "../connect/peopleTab";
import Tab2 from "../connect/companyTab";
import { useDispatch, useSelector } from "react-redux";
import { ioiuserSelector } from "../../api/ioiUser";
import ExploreIcon from "@mui/icons-material/Explore";
import MyFollowings from "./MyFollowings";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { RiUserFollowLine } from "react-icons/ri";
import { VscOrganization } from "react-icons/vsc";
import { MdPeople } from "react-icons/md";

export default function Connection() {
  const { token, user } = useSelector(ioiuserSelector);
  const [type, setType] = useState("company");
  const dispatch = useDispatch();

  return (
    <div>
      <section class="py-8" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-3">
            <div class="w-full xl:w-[22%] px-3 mb-12 lg:mb-0 mt-4">
              <div class="w-full mb-6">
                <div class="relative p-6 text-center bg-gray-100 rounded-xl">
                  <img
                    class="block mx-auto mb-5 w-28 h-28 rounded-full"
                    src={
                      user?.profile_completion?.logo
                        ? user?.profile_completion?.logo
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                    alt=""
                  />
                  <h4 class="text-lg text-[#0F3D79] font-bold mb-3">
                    {user?.name}
                  </h4>
                  <div class="flex items-center justify-center mb-6">
                    <p class="text-md font-semibold text-gray-500">
                      {user?.sub_category}
                    </p>
                  </div>
                </div>
              </div>
              <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <div
                  class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                  onClick={() => setType("company")}
                  style={{
                    backgroundColor: type == "company" ? "#CEDDF6" : "#F3F4F6",
                  }}
                >
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <VscOrganization />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Organisations
                    </h4>
                  </div>
                </div>
                <div
                  class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                  onClick={() => setType("people")}
                  style={{
                    backgroundColor: type == "people" ? "#CEDDF6" : "#F3F4F6",
                  }}
                >
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <MdPeople />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      People
                    </h4>
                  </div>
                </div>
                <div
                  class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                  onClick={() => setType("followings")}
                  style={{
                    backgroundColor:
                      type == "followings" ? "#CEDDF6" : "#F3F4F6",
                  }}
                >
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <RiUserFollowLine />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Following
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full xl:w-[78%] px-3 mt-4">
              <div class="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className=" flex text-white mb-5"></div>
                <div style={{ display: type == "company" ? "block" : "none" }}>
                  <Tab2 user={user} token={token} type={type} />
                </div>
                <div style={{ display: type == "people" ? "block" : "none" }}>
                  <Tab1 user={user} token={token} />
                </div>
                <div
                  style={{ display: type == "followings" ? "block" : "none" }}
                >
                  <MyFollowings user={user} token={token} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
