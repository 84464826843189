import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getIndividualProfile,
  individualUserSelector,
} from "../../api/individualUser";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function IndividualProfile() {
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const { id } = useParams();
  const { current_individual_user } = useSelector(individualUserSelector);
  const nav = useNavigate();
  const [copyStat, setCopyStat] = useState(false);
  const [email, setEmail] = useState("");

  const handleCopy = () => {
    navigator.clipboard
      .writeText(email)
      .then(() => {
        alert("Email copied to clipboard");
      })
      .catch((err) => {
        //console.error("Failed to copy email: ", err);
      });
  };

  useEffect(() => {
    dispatch(getIndividualProfile(id));
  }, [id]);

  const copyHandel = () => {
    setCopyStat(true);
    setTimeout(() => {
      setCopyStat(false);
    }, 4000);
  };

  return (
    <div>
      {current_individual_user?.profile_activate_status && (
        <section class="py-8" style={{ fontFamily: "Lato" }}>
          <div class="container  mx-auto">
            <div class="flex flex-wrap">
              <div class="w-full lg:w-[25%] px-3 mt-5">
                <div class="mb-6">
                  <div class="relative bg-gray-100 shadow rounded-lg overflow-hidden h-[300px]">
                    <img
                      class="relative w-32 h-32 mb-4 mt-4 mx-auto rounded-full object-cover object-right"
                      src={
                        current_individual_user?.profile_completion?.profile_pic
                          ? current_individual_user?.profile_completion
                              ?.profile_pic
                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                      }
                      alt=""
                    />
                    <div class="px-6 pb-5 text-center">
                      <p class="text-lg font-semibold text-[#0F3D79]">
                        {current_individual_user?.name}
                      </p>

                      {/* <p class="text-md font-semibold text-gray-500">{current_individual_user?.email}</p> */}
                      <p class="text-md font-semibold text-gray-500">
                        {current_individual_user?.profile_completion
                          ?.category === ""
                          ? ""
                          : current_individual_user?.profile_completion
                              ?.category === "Student"
                          ? "Student"
                          : current_individual_user?.profile_completion?.designation
                              ?.charAt(0)
                              .toUpperCase() +
                            current_individual_user?.profile_completion?.designation?.slice(
                              1
                            )}
                      </p>
                      <p class="text-md font-semibold text-gray-500">
                        {current_individual_user?.email}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="p-6 bg-gray-100 rounded-xl  h-[300px] mt-2">
                  <div class="flex flex-wrap -mx-3 mb-3 justify-between items-center">
                    <div class="w-auto px-3">
                      <h4 class="text-lg text-indigo-800 font-semibold">
                        Interested Domains
                      </h4>
                    </div>
                  </div>
                  <div class="w-full">
                    <div class="h-full rounded-xl">
                      <div class="mb-8 -mx-4">
                        <div class="-mb-3 text-start white-space-no-wrap">
                          {current_individual_user?.profile_completion?.skills.map(
                            (item, i) => {
                              return (
                                i < 6 && (
                                  <p class="px-2 pl-5 py-1 my-1 mb-1  text-gray-500 bg-gray-200 text-sm font-medium leading-6 rounded-full">
                                    {item}
                                  </p>
                                )
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-full lg:w-[50%] px-3 mt-5">
                <div class="container mx-auto">
                  <div class="relative bg-gray-100 shadow rounded-lg overflow-hidden  h-[300px]">
                    <div
                      className="px-3 py-3"
                      style={{
                        display:
                          current_individual_user?.profile_completion
                            ?.category == "Student"
                            ? "block"
                            : "none",
                      }}
                    >
                      <div className="flex p-2 ">
                        <div className="w-1/2 mr-5">
                          <div class="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
                            <div class="w-auto px-4 ">
                              <h3 class="text-lg font-semibold text-[#0F3D79] mb-4">
                                Education Details
                              </h3>
                            </div>
                          </div>

                          <div className="mt-5 text-md font-medium text-gray-500">
                            <p
                              className="font-semibold"
                              style={{ color: "#000" }}
                            >
                              {
                                current_individual_user?.profile_completion
                                  ?.education_details_1?.institute_name1
                              }
                            </p>
                            <p>
                              {
                                current_individual_user?.profile_completion
                                  ?.education_details_1?.education_level1
                              }
                            </p>
                            <p>
                              {
                                current_individual_user?.profile_completion
                                  ?.education_details_1?.course_name1
                              }
                            </p>
                            <p>
                              {
                                current_individual_user?.profile_completion
                                  ?.education_details_1?.start_year1 &&
                                current_individual_user?.profile_completion
                                  ?.education_details_1?.end_year1
                                  ? `${dayjs(
                                      current_individual_user.profile_completion
                                        .education_details_1.start_year1
                                    ).format("YYYY")}-${dayjs(
                                      current_individual_user.profile_completion
                                        .education_details_1.end_year1
                                    ).format("YYYY")}`
                                  : "" //display nothing
                              }
                            </p>
                          </div>

                          <div className="mt-5 text-md font-medium text-gray-500">
                            <p
                              className="font-semibold"
                              style={{ color: "#000" }}
                            >
                              {
                                current_individual_user?.profile_completion
                                  ?.education_details_2?.institute_name2
                              }
                            </p>
                            <p>
                              {
                                current_individual_user?.profile_completion
                                  ?.education_details_2?.education_level2
                              }
                            </p>
                            <p>
                              {
                                current_individual_user?.profile_completion
                                  ?.education_details_2?.course_name2
                              }
                            </p>
                            <p>
                              {
                                current_individual_user?.profile_completion
                                  ?.education_details_2?.start_year2 &&
                                current_individual_user?.profile_completion
                                  ?.education_details_2?.end_year2
                                  ? `${dayjs(
                                      current_individual_user.profile_completion
                                        .education_details_2.start_year2
                                    ).format("YYYY")}-${dayjs(
                                      current_individual_user.profile_completion
                                        .education_details_2.end_year2
                                    ).format("YYYY")}`
                                  : "" //display nothing
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="px-3 py-3"
                      style={{
                        display:
                          current_individual_user?.profile_completion
                            ?.category !== "Student"
                            ? "block"
                            : "none",
                      }}
                    >
                      <div className="flex p-2 ">
                        <div className="w-1/2 mr-5">
                          <div class="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
                            <div class="w-auto px-4 ">
                              <h3 class="text-lg font-semibold text-[#0F3D79] mb-4">
                                Education Details
                              </h3>
                            </div>
                          </div>

                          <div className="mt-5 text-md font-medium text-gray-500">
                            <p
                              className="font-semibold"
                              style={{ color: "#000" }}
                            >
                              {
                                current_individual_user?.profile_completion
                                  ?.education_details_1?.institute_name1
                              }
                            </p>
                            <p>
                              {
                                current_individual_user?.profile_completion
                                  ?.education_details_1?.education_level1
                              }
                            </p>
                            <p>
                              {
                                current_individual_user?.profile_completion
                                  ?.education_details_1?.course_name1
                              }
                            </p>
                            {/* <p>
                              {
                                current_individual_user?.profile_completion
                                  ?.education_details_1?.start_year1 &&
                                current_individual_user?.profile_completion
                                  ?.education_details_1?.end_year1
                                  ? `${dayjs(
                                      current_individual_user.profile_completion
                                        .education_details_1.start_year1
                                    ).format("YYYY")}-${dayjs(
                                      current_individual_user.profile_completion
                                        .education_details_1.end_year1
                                    ).format("YYYY")}`
                                  : "" //display nothing
                              }
                            </p> */}
                            <p>
                              {current_individual_user?.profile_completion
                                ?.education_details_1?.start_year1 &&
                              current_individual_user?.profile_completion
                                ?.education_details_1?.end_year1
                                ? `${dayjs(
                                    current_individual_user.profile_completion
                                      .education_details_1.start_year1
                                  ).format("YYYY")}-${
                                    current_individual_user.profile_completion
                                      .education_details_1.end_year1 ===
                                    "present"
                                      ? "Present" // Handle "present" case
                                      : dayjs(
                                          current_individual_user
                                            .profile_completion
                                            .education_details_1.end_year1
                                        ).format("YYYY")
                                  }`
                                : ""}
                            </p>
                          </div>

                          <div className="mt-5 text-md font-medium text-gray-500">
                            <p
                              className="font-semibold"
                              style={{ color: "#000" }}
                            >
                              {
                                current_individual_user?.profile_completion
                                  ?.education_details_2?.institute_name2
                              }
                            </p>
                            <p>
                              {
                                current_individual_user?.profile_completion
                                  ?.education_details_2?.education_level2
                              }
                            </p>
                            <p>
                              {
                                current_individual_user?.profile_completion
                                  ?.education_details_2?.course_name2
                              }
                            </p>
                            <p>
                              {
                                current_individual_user?.profile_completion
                                  ?.education_details_2?.start_year2 &&
                                current_individual_user?.profile_completion
                                  ?.education_details_2?.end_year2
                                  ? `${dayjs(
                                      current_individual_user.profile_completion
                                        .education_details_2.start_year2
                                    ).format("YYYY")}-${dayjs(
                                      current_individual_user.profile_completion
                                        .education_details_2.end_year2
                                    ).format("YYYY")}`
                                  : "" //display nothing
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="container mx-auto px-1 mt-6">
                  <div class="relative bg-gray-100 shadow rounded-lg overflow-hidden  h-[300px]">
                    <div
                      className="px-3 py-3"
                      style={{
                        display:
                          current_individual_user?.profile_completion
                            ?.category == "Student"
                            ? "block"
                            : "none",
                      }}
                    >
                      <div className="lg:flex px-3 py-3">
                        <div className="w-100 mr-5">
                          <div>
                            <div className="w-auto mt-1">
                              <h3 className="text-lg font-semibold text-[#0F3D79] mb-4">
                                Career Objectives
                              </h3>
                            </div>
                            <div className="text-md font-medium text-gray-500">
                              <p>
                                {
                                  current_individual_user?.profile_completion
                                    ?.career_objectives
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="px-3 py-3"
                      style={{
                        display:
                          current_individual_user?.profile_completion
                            ?.category !== "Student"
                            ? "block"
                            : "none",
                      }}
                    >
                      <div className="lg:flex  p-2">
                        <div className="w-100 mr-5">
                          <div className="flex flex-wrap -mx-4 -mb-4 justify-between items-center ml-0">
                            <div className="w-auto mt-1">
                              <h3 className="text-lg font-semibold text-[#0F3D79] mb-4">
                                Career Details
                              </h3>
                            </div>
                          </div>

                          <div className="mt-5 text-md font-medium text-gray-500">
                            <p
                              className="font-semibold"
                              style={{ color: "#000" }}
                            >
                              {
                                current_individual_user?.profile_completion
                                  ?.experience_details_1?.company_name1
                              }
                            </p>
                            <p>
                              {
                                current_individual_user?.profile_completion
                                  ?.experience_details_1?.designation1
                              }
                            </p>

                            <p>
                              {
                                current_individual_user?.profile_completion
                                  ?.experience_details_1?.company_location1
                              }
                            </p>
                            <p>
                              {current_individual_user?.profile_completion
                                ?.experience_details_1?.expStart_year1 &&
                              current_individual_user?.profile_completion
                                ?.experience_details_1?.expEnd_year1
                                ? `${dayjs(
                                    current_individual_user.profile_completion
                                      .experience_details_1.expStart_year1
                                  ).format("YYYY")}-${
                                    current_individual_user.profile_completion
                                      .experience_details_1.expEnd_year1 ===
                                    "present"
                                      ? "Present" // Handle "present" case
                                      : dayjs(
                                          current_individual_user
                                            .profile_completion
                                            .experience_details_1.expEnd_year1
                                        ).format("YYYY")
                                  }`
                                : ""}
                            </p>
                          </div>

                          <div className="mt-5 text-md font-medium text-gray-500">
                            <p
                              className="font-semibold"
                              style={{ color: "#000" }}
                            >
                              {
                                current_individual_user?.profile_completion
                                  ?.experience_details_2?.company_name2
                              }
                            </p>
                            <p>
                              {
                                current_individual_user?.profile_completion
                                  ?.experience_details_2?.designation2
                              }
                            </p>

                            <p>
                              {
                                current_individual_user?.profile_completion
                                  ?.experience_details_2?.company_location2
                              }
                            </p>
                            <p>
                              {current_individual_user?.profile_completion
                                ?.experience_details_2?.expStart_year2 &&
                              current_individual_user?.profile_completion
                                ?.experience_details_2?.expEnd_year2
                                ? `${dayjs(
                                    current_individual_user.profile_completion
                                      .experience_details_2.expStart_year2
                                  ).format("YYYY")}-${dayjs(
                                    current_individual_user.profile_completion
                                      .experience_details_2.expEnd_year2
                                  ).format("YYYY")}`
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-full lg:w-[25%] px-3 mt-5">
                <div class="p-6 bg-gray-100 rounded-xl h-[300px] overflow-y-auto">
                  <div class="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
                    <div class="w-auto px-4">
                      <h3 class="text-lg font-semibold text-[#0F3D79] mb-4">
                        Achievements and Awards
                      </h3>
                    </div>
                  </div>

                  <div className="mt-2">
                    <p class=" text-md font-semibold text-gray-800">
                      {
                        current_individual_user?.profile_completion
                          ?.award_details_1?.award1
                      }
                    </p>
                  </div>
                  <div className=" flex mt-2">
                    {current_individual_user?.profile_completion
                      ?.award_details_1?.awarded_year1 ? (
                      <h4 class="text-lg font-semibold text-[#0F3D79]">
                        {" "}
                        Year :
                      </h4>
                    ) : null}

                    <p class="ml-2 text-lg font-semibold text-gray-800">
                      {current_individual_user?.profile_completion
                        ?.award_details_1?.awarded_year1
                        ? dayjs(
                            current_individual_user?.profile_completion
                              ?.award_details_1?.awarded_year1
                          ).format("YYYY")
                        : ""}
                    </p>
                  </div>
                  <div className="mt-2">
                    <p class=" text-md font-semibold text-gray-800">
                      {
                        current_individual_user?.profile_completion
                          ?.achivement_details_1?.achivement1
                      }
                    </p>
                  </div>
                  <div className=" flex mt-2">
                    {current_individual_user?.profile_completion
                      ?.achivement_details_1?.achived_year1 ? (
                      <h4 class="text-lg font-semibold text-[#0F3D79]">
                        {" "}
                        Year :
                      </h4>
                    ) : null}

                    <p class="ml-2 text-lg font-semibold text-gray-800">
                      {current_individual_user?.profile_completion
                        ?.achivement_details_1?.achived_year1
                        ? dayjs(
                            current_individual_user?.profile_completion
                              ?.achivement_details_1?.achived_year1
                          ).format("YYYY")
                        : ""}
                    </p>
                  </div>
                </div>

                <div class=" mt-5 p-6 bg-gray-100 rounded-xl h-[300px]">
                  <div class="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
                    <div class="w-auto px-4">
                      <h3 class="text-lg font-semibold text-[#0F3D79] mb-4">
                        Skills
                      </h3>
                    </div>
                  </div>

                  <div className="mt-5">
                    <p class=" text-md font-semibold text-gray-800">
                      {
                        current_individual_user?.profile_completion
                          ?.skill_details1?.skill1
                      }
                    </p>
                  </div>
                  <div className="flex mt-2">
                    {current_individual_user?.profile_completion?.skill_details1
                      ?.skill_year1 ? (
                      <h4 className="text-lg font-semibold text-[#0F3D79]">
                        Details :
                      </h4>
                    ) : null}

                    <p className="ml-2 text-lg font-semibold text-gray-800">
                      {current_individual_user?.profile_completion
                        ?.skill_details1?.skill_year1
                        ? // Check if the skill year is a non-empty valid string
                          current_individual_user?.profile_completion
                            ?.skill_details1?.skill_year1 === "present"
                          ? "Present"
                          : current_individual_user?.profile_completion
                              ?.skill_details1?.skill_year1
                        : ""}
                    </p>
                  </div>
                  <div className="mt-5">
                    <p class=" text-md font-semibold text-gray-800">
                      {
                        current_individual_user?.profile_completion
                          ?.skill_details2?.skill2
                      }
                    </p>
                  </div>
                  <div className="flex mt-2">
                    {current_individual_user?.profile_completion?.skill_details1
                      ?.skill_year1 ? (
                      <h4 className="text-lg font-semibold text-[#0F3D79]">
                        Details :
                      </h4>
                    ) : null}

                    <p className="ml-2 text-lg font-semibold text-gray-800">
                      {current_individual_user?.profile_completion
                        ?.skill_details2?.skill_year2
                        ? // Check if the skill year is a non-empty valid string
                          current_individual_user?.profile_completion
                            ?.skill_details?.skill_year2 === "present"
                          ? "Present"
                          : current_individual_user?.profile_completion
                              ?.skill_details2?.skill_year2
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default IndividualProfile;
