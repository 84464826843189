import React from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { fetchIOIProfile, ioiuserSelector } from "../../api/ioiUser";
import { useDispatch, useSelector } from "react-redux";
import WorkIcon from "@mui/icons-material/Work";
import usePagination from '@mui/material/usePagination/usePagination';
import { jobsSelector } from '../../api/jobs';
import ApplicantCard from './ApplicantCard';

export default function Shortlist() {

    const { token, user } = useSelector(ioiuserSelector);
    const { currentjob } = useSelector(jobsSelector);
    const {id} = useParams()
    const {pathname} = useLocation()


  return (
    <div style={{ fontFamily: "Lato" }}>
  <section class="py-8" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-3">
            <div class="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <div class="w-full mb-6">
                <div class="relative p-6 text-center bg-gray-100 rounded-xl">
                  <img
                    class="block mx-auto mb-5 w-28 h-28 rounded-full"
                    src={
                      user?.profile_completion?.logo
                        ? user?.profile_completion?.logo
                        : "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"
                    }
                    alt=""
                  />
                  <h4 class="text-lg text-[#0F3D79] font-bold mb-3">
                    {user?.name}
                  </h4>
                  <div class="flex items-center justify-center mb-6">
                    <p class="text-md font-semibold text-gray-500">
                      {user?.sub_category}
                    </p>
                  </div>
                </div>
              </div>
              {/* <ProfileLogoInd user={user} /> */}
              <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to='/jobs'>
                <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200">
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <WorkIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Jobs Posted
                    </h4>
                  </div>
                </div>
                </NavLink>
                <NavLink to={`/applicants/${id}`}>
                <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200">
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <WorkIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Job Applicants
                    </h4>
                  </div>
                </div>
                </NavLink>
                <NavLink to={`/shortlist/${id}`}>
                <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                 style={{backgroundColor:pathname.includes("shortlist")?"#CEDDF6":"#F3F4F6"}}>
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <WorkIcon />
                  </div>
                  <div>
                    <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Job Shortlists
                    </h4>
                  </div>
                </div>
                </NavLink>
               
              </div>
            </div>
            
            <div class="w-full lg:w-[78%] px-3">
            <div class="flex h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                 {
                  currentjob?.shortlisted?.map((item, i) => {
                    return <ApplicantCard applicant={item} index={i} jobId={id} />
                  })
                 }
            </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
