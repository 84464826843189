import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri, config } from "../key";
import { toast } from "react-toastify";


export const initialState = {
  all_individual_user: [],
  current_individual_user: {},
  hasError: true,
  loading: false,
  user: null,
};
export const userSlice = createSlice({
  name: "individualuser",
  initialState,
  reducers: {
    getLoading: (state) => {
      state.loading = true;
    },
    getAllIndividual: (state, { payload }) => {
      state.all_individual_user = payload;
    },
    getOneIndividual :(state, { payload }) => {
      state.current_individual_user = payload;
    },
    getUserFailure: (state) => {
      state.hasError = true;
      state.isAuthenticate = false;
    },
  },
});

export const {
  getLoading,
  getAllIndividual,
  getOneIndividual,
  getUserFailure,
} = userSlice.actions;

export const individualUserSelector = (state) => state.individualuser;
export default userSlice.reducer;

export const fetchAllIndividualUser = () => async (dispatch) => {
  dispatch(getLoading());
  try {
    const { data } = await axios.get(keyUri.BACKEND_URI + "/users/all-individual");
    dispatch(getAllIndividual(data.individualUsers));
  } catch (error) {
    dispatch(getUserFailure(error));
  }
};


export const getIndividualProfile = (id) => async (dispatch) => {
  dispatch(getLoading());
  try {
    const { data } = await axios(
      keyUri.BACKEND_URI + `/users/get-one-individual/${id}`,
      config
    );
    dispatch(getOneIndividual(data?.indUser))
  
  } catch (response) {
    setTimeout(() => {
      toast.error(`${response.response.data.message}`, {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeButton:false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }, 200);
    dispatch(getUserFailure(response));
  }
};